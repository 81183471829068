<!-- 業務ポータルトップ -->
<template>
  <div class="view-compo-div">
    <!-- ヘッダ -->
    <header-bar ref="ref_header" />

    <!-- ボディ -->
    <v-main class="content text-center">
      <v-row>
        <v-col>
          <div>
            <h1 class="not-found-cd">404 Not Found</h1>
            <h1>ページが見つかりませんでした。</h1>
          </div>
          <div>
            <h2>URLが間違っているか、ページが削除された可能性があります。</h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <v-btn color="info" @click="redo">
              <v-icon>fas fa-undo</v-icon>&nbsp;直前の画面に戻る
            </v-btn>
            <v-btn color="success" @click="home">
              <v-icon>fas fa-home</v-icon>&nbsp;ホームに移動
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-main>

    <!-- フッタ -->
    <footer-bar ref="ref_footer" />

    <!-- 全画面ローディングエリア -->
    <loading :active.sync="isLoading" :can-cancel="false" is-full-page />
  </div>
</template>

<script>
import HeaderBar from "tds-colb-cmns/src/components/common/HeaderBar";
import FooterBar from "tds-colb-cmns/src/components/common/FooterBar";
// import HeaderBar from "@/components/common/HeaderBar";
// import FooterBar from "@/components/common/FooterBar";
import Loading from "vue-loading-overlay";

export default {
  data: () => ({
    isLoading: false
  }),
  created() {},
  methods: {
    redo() {
      this.isLoading = true;
      this.$router.go(-1);
    },
    home() {
      this.isLoading = true;
      this.$router.push({
        name: "colbFrame",
        params: { jobNavi: "home" }
      });
    }
  },
  computed: {},
  components: {
    HeaderBar,
    FooterBar,
    Loading
  }
};
</script>
<style scoped>
.content {
  height: 100%;
}

.content :deep(.layout) {
  height: 200px;
}

.not-found-cd {
  font-size: 56px;
}
</style>
