const rest = require("tds-colb-cmns/src/utils/api/restCmn");
const restUri = require("@/utils/api/restUri");
import { EmployeeListGetRequestModel } from "@/models/masterDataModels";

export default {
  // /*代理店区分リスト取得 */
  // getAgentdivList() {
  //   const uri = restUri.uriList.job_cmn_mst_agentdiv_list;
  //   return rest.get(uri);
  // },
  // /*業種区分リスト取得 */
  // getBizcatgdivList() {
  //   const uri = restUri.uriList.job_cmn_mst_bizcatgdiv_list;
  //   return rest.get(uri);
  // },
  // /*BU区分リスト取得 */
  // getBudivList() {
  //   const uri = restUri.uriList.job_cmn_mst_budiv_list;
  //   return rest.get(uri);
  // },
  // /*口銭ランクリスト取得 */
  // getCommrankList() {
  //   const uri = restUri.uriList.job_cmn_mst_commrank_list;
  //   return rest.get(uri);
  // },
  // /*事業部系列リスト取得 */
  // getDivseriesList() {
  //   const uri = restUri.uriList.job_cmn_mst_divseries_list;
  //   return rest.get(uri);
  // },
  // /*FDコードリスト取得 */
  // getFdcdList() {
  //   const uri = restUri.uriList.job_cmn_mst_fdcd_list;
  //   return rest.get(uri);
  // },
  /* 汎用リスト取得 */
  getGeneralpurposeList() {
    const uri = restUri.uriList.job_cmn_mst_generalpurpose_list;
    return rest.get(uri);
  },
  // /*手配区分リスト取得 */
  // getItemdivList() {
  //   const uri = restUri.uriList.job_cmn_mst_itemdiv_list;
  //   return rest.get(uri);
  // },
  // /*製番カテゴリリスト取得 */
  // getProductcatList() {
  //   const uri = restUri.uriList.job_cmn_mst_productcat_list;
  //   return rest.get(uri);
  // },
  // /*受注区分リスト取得 */
  // getRodivList() {
  //   const uri = restUri.uriList.job_cmn_mst_rodiv_list;
  //   return rest.get(uri);
  // },
  // /*SBUリスト取得 */
  // getSbuList() {
  //   const uri = restUri.uriList.job_cmn_mst_sbu_list;
  //   return rest.get(uri);
  // },
  // /*SPC区分リスト取得 */
  // getSpcdivList() {
  //   const uri = restUri.uriList.job_cmn_mst_spcdiv_list;
  //   return rest.get(uri);
  // },
  // /*税区分リスト取得 */
  // getTaxList() {
  //   const uri = restUri.uriList.job_cmn_mst_tax_list;
  //   return rest.get(uri);
  // },
  // /*税区分リスト取得(経過措置物件対応) */
  // getTaxListProgress({ chubanRyakuban }) {
  //   const uri =
  //     restUri.uriList.job_cmn_mst_tax_list_progress + "/" + chubanRyakuban;
  //   return rest.get(uri);
  // },
  // /*東芝産別取得 */
  // getTindstList() {
  //   const uri = restUri.uriList.job_cmn_mst_tindst_list;
  //   return rest.get(uri);
  // },
  // /*単位リスト取得 */
  // getUnitList() {
  //   const uri = restUri.uriList.job_cmn_mst_unit_list;
  //   return rest.get(uri);
  // },
  // /*有期労災附保区分リスト取得 */
  // getYukirosaiInsDivList() {
  //   const uri = restUri.uriList.job_cmn_mst_yukirosaiInsDiv_list;
  //   return rest.get(uri);
  // },
  /* 部課リスト取得（全部課） */
  getMstDepartList() {
    const uri = restUri.uriList.job_cmn_mst_depart_list;
    return rest.get(uri);
  },
  // /*計上部課リスト */
  // getMstApprdepartList(option) {
  //   const uri =
  //     restUri.uriList.job_cmn_mst_apprdepart_list +
  //     "/" +
  //     option.blngDepartCd +
  //     "/" +
  //     option.salesDivCd +
  //     "/" +
  //     option.salesChannelDivCd;
  //   return rest.get(uri);
  // },
  // /*権限部課リスト（選択項目用） */
  // getMstRoleDepartList(option) {
  //   const uri =
  //     restUri.uriList.job_cmn_mst_roledepart_list + "/" + option.blngDepartCd;
  //   return rest.get(uri);
  // },
  // /*製番一覧(ツリー) */
  // getTrnSeibanTree(roNo) {
  //   const uri = restUri.uriList.job_cmn_trn_seiban_tree + "/" + roNo;
  //   return rest.get(uri);
  // },
  // /*prcJobTree(ツリー) */
  // getPrcJobTree(jobClass, option) {
  //   const uri = restUri.uriList.job_cmn_mst_prcJobTree_get + "/" + jobClass;
  //   return rest.get(uri, option);
  // },
  // /*依頼用機種リスト */
  // getReqkishuList() {
  //   const uri = restUri.uriList.job_cmn_mst_reqkishu_list;
  //   return rest.get(uri);
  // },
  // /*山積点検区分リスト */
  // getHeapCheckDivList() {
  //   const uri = restUri.uriList.job_cmn_mst_heapcheckdiv_list;
  //   return rest.get(uri);
  // },
  // /*売上区分リスト */
  // getSalesDivList() {
  //   const uri = restUri.uriList.job_cmn_salesDiv_list;
  //   return rest.get(uri);
  // },
  // /*商流区分リスト */
  // getChannelsDivList() {
  //   const uri = restUri.uriList.job_cmn_channelsDiv_list;
  //   return rest.get(uri);
  // },
  /* 従業員リスト */
  getEmployeeList(form = EmployeeListGetRequestModel) {
    const uri = restUri.uriList.job_cmn_employee_list;
    const paramStr = new URLSearchParams(form).toString();
    return rest.get(`${uri}?${paramStr}`);
  },
  // /* ステータスリスト */
  // getStatusList() {
  //   const uri = restUri.uriList.job_cmn_mst_statusmng_list;
  //   return rest.get(uri);
  // },
  // /* 建設業許可種類リスト */
  // getBldIndustryLicTyp() {
  //   const uri = restUri.uriList.job_cmn_mst_bldIndustryLicTyp_list;
  //   return rest.get(uri);
  // },
  // /* 設備分類名リスト */
  // getFacilityClassList() {
  //   const uri = restUri.uriList.job_cmn_mst_facilityClass_list;
  //   return rest.get(uri);
  // },
  // /* 技術者管理部課マスタリスト */
  // getEngineerMngDepartList() {
  //   const uri = restUri.uriList.job_cmn_mst_engineerMngDepart_list;
  //   return rest.get(uri);
  // },
  // /* 基本ヘッダー_SR売上HEADリスト */
  // getBasHeadSrSalesHeadList(seibanRyakuban) {
  //   const uri =
  //     restUri.uriList.job_cmn_basHeadSrSalesHead_list + "/" + seibanRyakuban;
  //   return rest.get(uri);
  // },
  // /* 発注先マスタ */
  // getContactOrder(toricoCd) {
  //   const uri = restUri.uriList.job_cmn_contactOrder_get + toricoCd;
  //   return rest.get(uri);
  // },
  // /* 発注先 建設業許可リスト */
  // getBuildingPermitList(toricoCd) {
  //   const uri = restUri.uriList.job_cmn_buildingPermit_get + toricoCd;
  //   return rest.get(uri);
  // },
  // /* 受渡条件情報取得 */
  // getDelvCond(options) {
  //   const uri = restUri.uriList.job_cmn_delvCnd_list;
  //   return rest.get(uri, options);
  // },
  // /* 設置場所情報取得 */
  // getSiteInfo(siteNo) {
  //   const uri = restUri.uriList.job_cmn_siteInfo_get + siteNo;
  //   return rest.get(uri);
  // },
  // /* 権限部課リスト取得（勘定年月：TDSAPL、部課指定） */
  // getRoleDepartListByTdsapl(departCd) {
  //   const uri =
  //     restUri.uriList.job_cmn_mst_roleDepart_list_tdsapl + "/" + departCd;
  //   return rest.get(uri);
  // },
  // /** 集計ポイントマスタリスト（計上部門リスト）を取得 */
  // getAccoDepList() {
  //   const uri = restUri.uriList.job_cmn_department_accoDep_List;
  //   return rest.get(uri);
  // },
  // /** 部課マスタVIEWリストを取得 */
  // getDeptViewList(totalDivCd) {
  //   const uri =
  //     restUri.uriList.job_cmn_department_deptView_List + "/" + totalDivCd;
  //   return rest.get(uri);
  // },
  // /** 指示者区分リストを取得 */
  // getIndictrDivList() {
  //   const uri = restUri.uriList.job_cmn_mst_generalpurpose_list + "/" + "073";
  //   return rest.get(uri);
  // },
  // /** TdsToricoEdi を取得 */
  // getTdsToricoEdi(toricoCd) {
  //   const uri = restUri.uriList.job_cmn_mst_tdsToricoEdi_get + "/" + toricoCd;
  //   return rest.get(uri);
  // },
  // /** TdsToricoEdi：EDI連携情報 を取得 */
  // getTdsToricoEdiInfoBySeibanRyakuban(seibanRyakuban) {
  //   const uri =
  //     restUri.uriList.job_cmn_mst_tdsToricoEdi_getBySeibanryakuban +
  //     "/" +
  //     seibanRyakuban;
  //   return rest.get(uri);
  // },
  // /** TdsToricoEdi：EDI連携情報 を取得 */
  // getTdsToricoEdiInfoBySeibanRyakubanAndToricoCd(seibanRyakuban, toricoCd) {
  //   const uri =
  //     restUri.uriList.job_cmn_mst_tdsToricoEdi_getBySeibanryakuban_toricoCd +
  //     "/" +
  //     seibanRyakuban +
  //     "/" +
  //     toricoCd;
  //   return rest.get(uri);
  // },
  // /** 拠点一覧取得 */
  // getBaseLocList() {
  //   const engr_cmn_base_loc_list = "/engr/api/common-mst/base-loc-list";
  //   return rest.get(engr_cmn_base_loc_list);
  // },
  // /** 拠点部課一覧取得 */
  // getBaseDeptList(baseCd = "") {
  //   const engr_cmn_base_dept_list = "/engr/api/common-mst/base-dept-list";
  //   return rest.get(`${engr_cmn_base_dept_list}/${baseCd}`);
  // },
  getMngDepartBaseList() {
    const uri = restUri.uriList.job_cmn_department_base_List;
    return rest.get(uri);
  },
  /** 管理部課一覧取得 */
  // getMngDeptList() {
  //   const engr_cmn_mng_dept_list = "/engr/api/common-mst/mng-dept-list";
  //   return rest.get(engr_cmn_mng_dept_list);
  // },
  // ログイン者の権限情報を取得
  getMngrRoles() {
    const engr_login_user_roles = "/mbo/api/login-user-roles";
    return rest.get(engr_login_user_roles);
  },
};
