<!--機能ナビゲーション(コンテンツメニュー)-->
<template>
  <v-navigation-drawer
    class="func-navi-drawer"
    :value="drawer"
    :mini-variant.sync="funcNaviMini"
    :mini-variant-width="miniVariantWidth"
    permanent
  >
    <v-toolbar flat class="transparent">
      <v-list class="pa-0 mx-auto">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="navi-title text-center">メニュー</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-toolbar>

    <v-list class="navi-contents pt-0 px-0" dense>
      <template v-for="(item, i) in items">
        <v-list-item
          dense
          v-show="!item.invisible"
          :key="i"
          :disabled="item.disabled"
          @click.stop="changeContents(item.colbContentsMenuInfoId)"
        >
          <v-list-item-title>
            <v-icon small>fas fa-sharp fa-square</v-icon>
            {{ item.colbContentsMenuNam }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
    <action-confirm ref="actionConfirm" />
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ActionConfirm from "@/components/common/dialog/ActionConfirm";
import { ContentsMenuModel } from "@/models/colbCommonModels";
import colbMixin from "@/mixins/colbMixin";
import service from "@/services/colb/colbService";

export default {
  props: {
    drawer: {
      type: Boolean,
      default: true
    },
    value: null,
    miniVariantWidth: {
      type: [Number, String],
      default: 0
    }
  },
  model: {
    prop: "value",
    event: "change"
  },
  mixins: [colbMixin],
  data: () => ({
    items: []
  }),
  methods: {
    changeMini() {
      this.funcNaviMini = !this.funcNaviMini;
    },
    changeContents(id = "") {
      // コンテンツメニュー一覧をjobNaviで検索
      const item = this.items.find(item => item.colbContentsMenuInfoId === id);
      const { jobNavi, jobFunc, apiPath, linkTargetUrl } = item;

      if (jobNavi) {
        // 協業型ポータル内の遷移
        this.$emit("change", jobNavi, jobFunc);
      } else if (apiPath) {
        // 協業ポータル以外APIへ遷移
        window.location.href = window.location.origin + apiPath;
      } else if (linkTargetUrl) {
        // 外部リンク
        const message = `外部サイト${linkTargetUrl}\nを開きます。よろしいですか？`;
        this.$refs.actionConfirm.confirm({ message: message }).then(wait => {
          if (wait) window.open(linkTargetUrl, "_blank");
        });
      }
    },
    getContentsMenu() {
      // コンテンツメニュー取得
      service
        .getContentsMenu()
        .then(res => {
          this.setContentsMenu(res.resultData);
        })
        .catch(err => {
          console.debug("getconmenFail", err);
          this.commonFail(err);
        })
        .finally(() => {
          this.commonFinally();
        });
    },
    setContentsMenu(list = [{ ...ContentsMenuModel }]) {
      const items = [];
      list.forEach(e => items.push({ ContentsMenuModel, ...e }));
      this.$set(this, "items", items);
    },
    ...mapActions("funcNavi", ["setFuncNaviMini"])
  },
  created() {
    this.getContentsMenu();
  },
  computed: {
    funcNaviMini: {
      set(val) {
        this.setFuncNaviMini(val);
      },
      get() {
        return this.$store.state.funcNavi.mini;
      }
    },
    ...mapState({
      funcNavi: state => state.funcNavi
    })
  },
  mounted() {},
  watch: {
    drawer() {
      this.changeMini();
    }
  },
  components: {
    ActionConfirm
  }
};
</script>
<style scoped>
.func-navi-drawer {
  font-size: 14px !important;
  min-width: initial;
}

.func-navi-drawer :deep(header) {
  height: 48px !important;
}

.func-navi-drawer :deep(.v-toolbar__content) {
  height: 48px !important;
  padding-left: 4px;
}

.func-navi-drawer :deep(.v-list-item) {
  padding-left: 12px;
}

.navi-contents :deep(.v-list-item) {
  padding-top: 8px;
  padding-bottom: 8px;
}

.open-width {
  width: 200px;
}

.func-navi-drawer :deep(.mx-auto),
.func-navi-drawer :deep(.ma-2) {
  position: relative;
}

.navi-title {
  width: 180px;
}
</style>
