/* 協業型ポータル：共通モデル */
// コンテンツメニュー
export const ContentsMenuModel = Object.freeze({
  colbContentsMenuInfoId: null, // 協業コンテンツメニュー情報ID
  colbContentsMenuNam: null, // 協業コンテンツメニュー名
  jobNavi: null, // 未設定時は外部サイトor協業ポータル以外のapi
  jobFunc: null,
  apiPath: null, // tdsaplの協業ポータル以外URL
  linkTargetUrl: null, // リンク先URL
  disabled: false,
  invisible: false,
});

export default [ContentsMenuModel];
