import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@fortawesome/fontawesome-free/css/all.css";
import "vue-loading-overlay/dist/vue-loading.css";
import "@/sass/main.scss";
import "tds-colb-cmns/src/assets/css/tdsapl.css";
import { VDataTableV2 } from "tds-colb-cmns/src/components/common/VDataTableV2";

Vue.config.productionTip = false;

Vue.component(VDataTableV2.options.name, VDataTableV2);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
