/**
 *
 * rest.jsのgetByManualUrlをするときのURL参照先
 * （都度、URLをハードコーディングすることを防ぐ）
 * その他の用途に使ってもOK！
 *
 */

// ここにURLを指定していく

export const APP_URL = process.env.VUE_APP_URL;
export const BASE_URL = process.env.VUE_APP_BASE_URL;
export const AUTH_BASE_URL = process.env.VUE_APP_AUTH_SERVER_ENDPOINT;
export const HELP_URL = process.env.VUE_APP_HELP_PATH;
export const PASSWORD_CHANGE_URL = process.env.VUE_APP_PASSWORD_CHANGE_PATH;
