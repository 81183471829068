// date: 日付オブジェクト
// format: 書式フォーマット
/** Date文字列を指定のフォーマットに変換します。
 *  ※必ずDate型に変換できる文字列で引き渡すこと。
 */
export function formatDate(date, format) {

  const pureNumDate = date.replace(/[^0-9]/g, '');
  let targetVal = "";

  if(pureNumDate !== undefined && pureNumDate !== null && pureNumDate !== ""){

    switch (pureNumDate.length) {
      // YYYYMM
      case 6:
        targetVal = pureNumDate.substr(0, 4) + "-" + pureNumDate.substr(4, 2);
        break;
      // YYYYMMDD
      case 8:
        targetVal = pureNumDate.substr(0, 4) + "-" + pureNumDate.substr(4, 2) + "-" + pureNumDate.substr(6, 2);
        break;
      // YYYYMMDDHH
      case 10:
        targetVal = pureNumDate.substr(0, 4) + "-" + pureNumDate.substr(4, 2) + "-" + pureNumDate.substr(6, 2) + " " + pureNumDate.substr(8, 2);
        break;
      // YYYYMMDDHHMM
      case 12:
        targetVal = pureNumDate.substr(0, 4) + "-" + pureNumDate.substr(4, 2) + "-" + pureNumDate.substr(6, 2) + " " + pureNumDate.substr(8, 2) + ":" + pureNumDate.substr(10, 2);
        break;
      // YYYYMMDDHHmmss
      case 14:
        targetVal = pureNumDate.substr(0, 4) + "-" + pureNumDate.substr(4, 2) + "-" + pureNumDate.substr(6, 2) + " " + pureNumDate.substr(8, 2) + ":" + pureNumDate.substr(10, 2) + ":" + pureNumDate.substr(12, 2);
        break;
      // YYYYMMDDHHmmssSSS
      case 17:
        targetVal = pureNumDate.substr(0, 4) + "-" + pureNumDate.substr(4, 2) + "-" + pureNumDate.substr(6, 2) + " " + pureNumDate.substr(8, 2) + ":" + pureNumDate.substr(10, 2) + ":" + pureNumDate.substr(12, 2) + "." + pureNumDate.substr(14, 3);
        break;
    }
  }

  date = new Date(targetVal);
  date.setTime(date.getTime());// JSTに変換

  format = format.replace(/yyyy/g, date.getFullYear());
  format = format.replace(/yy/g, date.getFullYear().toString().substr(2,2));
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
  format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
  format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
  return format;
}

/**
 * 現在日時をYYYY-MM-DD HH:mm:ssの形式で取得します。
 */
export function getNowDateTimeString(format) {
  let tmpDateTime = new Date();

  let tmpYear = tmpDateTime.getFullYear();
  let tmpMonth = (('00' + (tmpDateTime.getMonth()+1) ).slice( -2 ));
  let tmpDate =  (('00' + tmpDateTime.getDate() ).slice( -2 ));
  let tmpHour = (('00' + tmpDateTime.getHours() ).slice( -2 ));
  let tmpMin = (('00' + tmpDateTime.getMinutes() ).slice( -2 ));
  let tmpSec = (('00' + tmpDateTime.getSeconds() ).slice( -2 ));
  let tmpMilliSec = (('000' + tmpDateTime.getMilliseconds() ).slice( -3 ));

  let returnVal = "";
  switch (format) {
    case "YYYY":
      returnVal = tmpYear;
      break;
    case "YYYYMM":
      returnVal = tmpYear + "-" + tmpMonth;
      break;
    case "YYYYMMDD":
      returnVal = tmpYear + "-" + tmpMonth + "-" + tmpDate;
      break;
    case "YYYYMMDDhh":
      returnVal = tmpYear + "-" + tmpMonth + "-" + tmpDate + " " + tmpHour;
      break;
    case "YYYYMMDDhhmm":
      returnVal = tmpYear + "-" + tmpMonth + "-" + tmpDate + " " + tmpHour + ":" + tmpMin;
      break;
    case "YYYYMMDDhhmmss":
      returnVal = tmpYear + "-" + tmpMonth + "-" + tmpDate + " " + tmpHour + ":" + tmpMin + ":" + tmpSec;
      break;
    case "YYYYMMDDhhmmssSSS":
      returnVal = tmpYear + "-" + tmpMonth + "-" + tmpDate + " " + tmpHour + ":" + tmpMin + ":" + tmpSec + "." + tmpMilliSec;
      break;
  }

  return returnVal;
}

/**
 * エンティティに入っている日時をDatePickerやnewDateできる形へ変換する。
 * month         =>  yyyy-MM
 * date          =>  yyyy-MM-dd
 * dateTimeHour  =>  yyyy-MM-dd HH
 * dateTimeMin   =>  yyyy-MM-dd HH:mm
 * dateTime      =>  yyyy-MM-dd HH:mm:ss
 * timestamp     =>  yyyy-MM-dd HH:mm:ss.SSS
 */
export function formatDateString(date, format){

  let targetVal = "";

  if(date !== undefined && date !== null && date !== ""){

    const pureNumDate = date.replace(/[^0-9]/g, '');
  
    switch (format) {
      // YYYYMM
      case "month":
        targetVal = pureNumDate.substr(0, 4) + "-" + pureNumDate.substr(4, 2);
        break;
      // YYYYMMDD
      case "date":
        targetVal = pureNumDate.substr(0, 4) + "-" + pureNumDate.substr(4, 2) + "-" + pureNumDate.substr(6, 2);
        break;
      // YYYYMMDDHH
      case "dateTimeHour":
        targetVal = pureNumDate.substr(0, 4) + "-" + pureNumDate.substr(4, 2) + "-" + pureNumDate.substr(6, 2) + " " + pureNumDate.substr(8, 2);
        break;
      // YYYYMMDDHHMM
      case "dateTimeMin":
        targetVal = pureNumDate.substr(0, 4) + "-" + pureNumDate.substr(4, 2) + "-" + pureNumDate.substr(6, 2) + " " + pureNumDate.substr(8, 2) + ":" + pureNumDate.substr(10, 2);
        break;
      // YYYYMMDDHHmmss
      case "dateTime":
        targetVal = pureNumDate.substr(0, 4) + "-" + pureNumDate.substr(4, 2) + "-" + pureNumDate.substr(6, 2) + " " + pureNumDate.substr(8, 2) + ":" + pureNumDate.substr(10, 2) + ":" + pureNumDate.substr(12, 2);
        break;
      // YYYYMMDDHHmmssSSS
      case "timestamp":
        targetVal = pureNumDate.substr(0, 4) + "-" + pureNumDate.substr(4, 2) + "-" + pureNumDate.substr(6, 2) + " " + pureNumDate.substr(8, 2) + ":" + pureNumDate.substr(10, 2) + ":" + pureNumDate.substr(12, 2) + "." + pureNumDate.substr(14, 3);
        break;
    }
  }

  if(isNaN(new Date(targetVal))){
    return date;
  }

  return targetVal;
}

/**
 * 文字列の日付が引数のフォーマットにおいて妥当な日付かを判定する
 * @param {*} date 
 * @param {*} format 
 */
export function validationDate(date){
  if(!date || isNaN(new Date(date))){return false;}
  return true;
}

/**
 * 引数のDate型の日付を指定したフォーマットで取得する。
 * 内部共通のハイフン区切りの文字列
  * @param {*} dateStr 
  * @param {*} format 
  * @param {*} {target, prefix} 
  */
export function getDateTimeString(dateStr, format, {target, prefix}) {
  let tmpDateTime = new Date(dateStr);

  // prefixが設定されている場合のみ計算を実施
  if(target && prefix){
    if(target && target == 'year'){
      // console.log(target + ' = "target"' + " && target == 'year' = " + target == 'year');
      tmpDateTime.setFullYear(tmpDateTime.getFullYear() + prefix);
    }
    if(target && target == 'month'){
      // console.log(target + ' = "target"' + " && target == 'month' = " + target == 'month');
      tmpDateTime.setMonth(tmpDateTime.getMonth() + prefix);
    }
    if(target && target == 'day'){
      // console.log(target + ' = "target"' + " && target == 'day' = " + target == 'day');
      tmpDateTime.setDate(tmpDateTime.getDate() + prefix);
    }
    if(target && target == 'hour'){
      // console.log(target + ' = "target"' + " && target == 'hour' = " + target == 'hour');
      tmpDateTime.setHours(tmpDateTime.getHours() + prefix);
    }
    if(target && target == 'min'){
      // console.log(target + ' = "target"' + " && target == 'min' = " + target == 'min');
      tmpDateTime.setMinutes(tmpDateTime.getMinutes() + prefix);
    }
    if(target && target == 'sec'){
      // console.log(target + ' = "target"' + " && target == 'sec' = " + target == 'sec');
      tmpDateTime.setSeconds(tmpDateTime.getSeconds() + prefix);
    }
  }

  // console.log(tmpDateTime);

  let tmpYear = tmpDateTime.getFullYear();
  let tmpMonth = (('00' + (tmpDateTime.getMonth()+1) ).slice( -2 ));
  let tmpDate =  (('00' + tmpDateTime.getDate() ).slice( -2 ));
  let tmpHour = (('00' + tmpDateTime.getHours() ).slice( -2 ));
  let tmpMin = (('00' + tmpDateTime.getMinutes() ).slice( -2 ));
  let tmpSec = (('00' + tmpDateTime.getSeconds() ).slice( -2 ));
  let tmpMilliSec = (('000' + tmpDateTime.getMilliseconds() ).slice( -3 ));

  let returnVal = "";
  switch (format) {
    case "YYYY":
      returnVal = tmpYear;
      break;
    case "YYYYMM":
      returnVal = tmpYear + "-" + tmpMonth;
      break;
    case "YYYYMMDD":
      returnVal = tmpYear + "-" + tmpMonth + "-" + tmpDate;
      break;
    case "YYYYMMDDhh":
      returnVal = tmpYear + "-" + tmpMonth + "-" + tmpDate + " " + tmpHour;
      break;
    case "YYYYMMDDhhmm":
      returnVal = tmpYear + "-" + tmpMonth + "-" + tmpDate + " " + tmpHour + ":" + tmpMin;
      break;
    case "YYYYMMDDhhmmss":
      returnVal = tmpYear + "-" + tmpMonth + "-" + tmpDate + " " + tmpHour + ":" + tmpMin + ":" + tmpSec;
      break;
    case "YYYYMMDDhhmmssSSS":
      returnVal = tmpYear + "-" + tmpMonth + "-" + tmpDate + " " + tmpHour + ":" + tmpMin + ":" + tmpSec + "." + tmpMilliSec;
      break;
  }

  return returnVal;
}