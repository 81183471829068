<script>
const dateFormat = require("@/utils/dateFormat");
const Decimal = require("decimal");

export default {
  methods: {
    isExistValue(value) {
      return value === undefined || value === null || value === ""
        ? false
        : true;
    },
    // 適切なWidthを取得する
    // widthMap {xs: Number, sm: Number, md: Number, lg: Number, xl: Number, default: Number}
    // xs < 600 < sm < 960 < md < 1264 < lg < 1904 < xl
    widthSelector(widthMap) {
      let returnWidth = null;

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          returnWidth = widthMap.xs;
          break;
        case "sm":
          returnWidth = widthMap.sm;
          break;
        case "md":
          returnWidth = widthMap.md;
          break;
        case "lg":
          returnWidth = widthMap.lg;
          break;
        case "xl":
          returnWidth = widthMap.xl;
          break;
        default:
          returnWidth = widthMap.default;
          break;
      }

      return returnWidth;
    },

    // 操作履歴情報を押下時
    jump(url) {
      // URLに「http(s)」が含まれない場合は相対パスとみなして内部的遷移
      if (!~url.indexOf("http://") && !~url.indexOf("https://")) {
        let currentUrl = location.href;
        // 同一URIの場合はpushすると画面遷移が起こらないため、GOで画面再描画を行う
        if (currentUrl.indexOf(url) > 0) {
          this.$router.go(url);
        } else {
          this.$router.push(url);
        }
        return true;
      } else {
        // 通知文書のお知らせの場合は同画面以外別タブ表示
        if (
          (url.indexOf("?archive_num=") || url.indexOf("?notify_no=")) &&
          !~url.indexOf(this.$route.path)
        ) {
          return window.open(url, "_blank");
        }

        // URLに基幹システムのHostが含まれる場合は同タブでページ遷移
        if (~url.indexOf(location.host)) {
          window.location.href = url;
          // そうでなければ別タブでオープン
        } else {
          window.open(url, "_blank");
        }
      }
    },

    /** 対象のURLが外部URLか否かを返す */
    /** true:外部URL false:内部URL */
    isExternalUrl(url) {
      // nullの場合は内部URLとみなす
      if (url == null) {
        return false;
      }
      // URLに「http(s)」が含まれない場合は内部URLとみなす
      if (!~url.indexOf("http://") && !~url.indexOf("https://")) {
        return false;
      } else {
        // URLに基幹システムのHostが含まれる場合は内部URLとみなす
        if (~url.indexOf(location.host)) {
          return false;
          // そうでなければ外部URL
        } else {
          return true;
        }
      }
    },

    /** 日付表示形式(date, month, datetime)を取得 */
    getDateFormats() {
      return {
        date: "yyyy-MM-dd",
        month: "yyyy-MM",
        dateTimeMin: "yyyy-MM-dd HH:mm",
        dateTime: "yyyy-MM-dd HH:mm:ss",
        timestamp: "yyyy-MM-dd HH:mm:ss.SSS"
      };
    },

    isTextWrapped() {
      return true;
    },

    formatDate(date, format) {
      if (date == null || date == "") return date;
      return dateFormat.formatDate(date, format);
    },

    formatDateString(date, format) {
      if (date == null || date == "") return date;
      return dateFormat.formatDateString(date, format);
    },

    getNowDateString(format) {
      if (format == null || format == "") return "";
      return dateFormat.getNowDateTimeString(format);
    },

    formatMoney(value) {
      if (value !== null && !isNaN(Number(value))) {
        return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }
      return value;
    },

    removeComma(value) {
      if (value != undefined && value !== null) {
        return Number(value.toString().replace(/,/g, ""));
      }
      return value;
    },

    nullToHyphen(value) {
      if (value == null || value == "") return "-";
      else return value;
    },

    // 文字数バイトチェック
    checkStrByte(value, maxLength) {
      if (!value) return true;

      if (this.calcByte(value) <= maxLength) return true;
      else return false;
    },

    // 文字列のバイト数計算（半角は1バイト、全角は2バイト）
    calcByte(value) {
      var length = 0;
      for (var i = 0; i < value.length; i++) {
        var c = value.charCodeAt(i);
        if (
          (c >= 0x0 && c < 0x81) ||
          c === 0xf8f0 ||
          (c >= 0xff61 && c < 0xffa0) ||
          (c >= 0xf8f1 && c < 0xf8f4)
        ) {
          length += 1;
        } else {
          length += 2;
        }
      }
      return length;
    },

    // 表示対象のデータがnullやundefinedの場合、特定の表記に差し替える
    nullToAnyLabel(value, label) {
      if (value == undefined || value == null) return label;
      else return value;
    },

    /**
     * 開始日時と終了日時から期間を算出する
     *
     * @param {String} startDate 開始日時
     * @param {String} endDate 終了日時
     *
     * @returns {String} 経過期間
     */
    getPeriod(startDate, endDate) {
      if (!startDate || !endDate) return 0;

      const start = new Date(startDate);
      const end = new Date(endDate);

      const period = (end - start) / 86400000; //1日をミリ秒に換算した値で割ると、経過日数を取得できる
      console.debug("期間:", period);

      // 開始日を含めるので、+1する
      return period + 1;
    },

    /**
     * 期間が逆転していないかチェックする
     *
     * @param {String|Date} start 開始日時
     * @param {String|Date} end 終了日時
     *
     * @returns {boolean} 逆転有無(false:逆転、true:正転)
     */
    checkPeriod(start, end) {
      if (start == null || start == "" || end == null || end == "") {
        return true;
      }
      const from = new Date(start);
      const to = new Date(end);

      if (from.getTime() > to.getTime()) {
        return false;
      } else {
        return true;
      }
    },

    /**
     * 指定期間がNか月内となっているチェックする
     *
     * @param {String|Date} start 開始日時
     * @param {String|Date} end 終了日時
     * @param {String} maxManth 月数
     *
     * @returns {boolean} true:期間内 false:期間外
     */
    checkTerm(start, end, maxManth) {
      if (start == null || start == "" || end == null || end == "") {
        return true;
      }
      const from = new Date(start);
      const to = new Date(end);

      // fromのNか月後を算出
      let addedFrom = this.getAddMonthDate(
        from.getFullYear(),
        from.getMonth() + 1,
        from.getDate(),
        maxManth
      );

      // Nか月後のfromがtoを超えていないかチェック
      if (addedFrom.getTime() >= to.getTime())
        // fromのが大きい＝期間内なのでtrue
        return true;
      // toのが大きい＝Nか月後の日付よりも長いのでfalse
      else return false;
    },

    getAddMonthDate(year, month, day, add) {
      let addMonth = month + add;
      let endDate = this.getEndOfMonth(year, addMonth); //add分を加えた月の最終日を取得

      //引数で渡された日付がnヶ月後の最終日より大きければ日付を次月最終日に合わせる
      //5/31→6/30のように対応日が無い場合に必要
      if (day > endDate) {
        day = endDate;
      } else {
        day = day - 1;
      }

      let addMonthDate = new Date(year, addMonth - 1, day);
      return addMonthDate;
    },

    //今月の月末日を取得
    //※次月の0日目＝今月の末日になる
    getEndOfMonth(year, month) {
      let endDate = new Date(year, month, 0);
      return endDate.getDate();
    },

    /**
     * ファイル名から拡張子を取得する
     */
    getFileExtension(fileName) {
      return fileName.split(".").pop();
    },

    /** ヘッダーからファイル名を取得する */
    getFileName(headers = {}) {
      if (Object.keys(headers).length > 0) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/; // 正規表現でfilenameを抜き出す
        const disposition = headers["content-disposition"];
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          const fileName = matches[1].replace(/['"]/g, "");
          return decodeURI(fileName);
        }
      }
      return "";
    },
    /** ArrayBufferをオブジェクトに変換する */
    convertToStandardResponse(resultData) {
      const resultDataJson = JSON.parse(
        new TextDecoder().decode(resultData || {})
      );
      return {
        response: {
          resultData: resultDataJson,
          message: resultDataJson.message
        }
      };
    },

    // 数値計算；足し算
    decimalCalcAdd(num1, num2) {
      return Decimal(this.nullToAnyLabel(num1, 0))
        .add(this.nullToAnyLabel(num2, 0))
        .toNumber();
    },

    // 数値計算；引き算
    decimalCalcSub(num1, num2) {
      return Decimal(this.nullToAnyLabel(num1, 0))
        .sub(this.nullToAnyLabel(num2, 0))
        .toNumber();
    },

    // 数値計算；かけ算
    decimalCalcMul(num1, num2) {
      return Decimal(this.nullToAnyLabel(num1, 0))
        .mul(this.nullToAnyLabel(num2, 0))
        .toNumber();
    },

    // 数値計算；割り算
    decimalCalcDiv(num1, num2) {
      return Decimal(this.nullToAnyLabel(num1, 0))
        .div(this.nullToAnyLabel(num2, 0))
        .toNumber();
    }
  },
  computed: {
    textWrapClass() {
      return this.isTextWrapped();
    },
    tableTextWrapClass() {
      // return this.isTableTextWrapped();
      return false;
    },
    sysdate() {
      return dateFormat.getNowDateTimeString("YYYYMMDD");
    }
  },
  filters: {
    dateFormatter: function(date, format) {
      if (date === undefined || date === null || date === "") {
        return "";
      }
      return dateFormat.formatDate(date, format);
    }
  }
};
</script>
