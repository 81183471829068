<template>
  <v-app class="font-md" :class="{ 'sp-layout': currentWidth < 769 }">
    <router-view :key="$route.fullPath" :style="{ flex: '1 1 auto' }" />
    <loading :active="isLoading" is-full-page :z-index="zIndex" />
  </v-app>
</template>

<script>
import colbMixin from "@/mixins/colbMixin";
import Loading from "vue-loading-overlay";

export default {
  name: "app",
  model: {},
  mixins: [colbMixin],
  props: {},
  data: () => ({
    zIndex: 4,
    currentWidth: 1920,
  }),
  methods: {
    createTitleDesc: function (routeInstance) {
      //titleを設定する処理
      const baseTitle = "";
      const commonTitle = baseTitle + "";
      if (routeInstance.meta.title) {
        const setTitle = baseTitle + " " + routeInstance.meta.title;
        document.title = setTitle;
      } else {
        document.title = commonTitle;
      }
    },

    calcWidth() {
      this.currentWidth = window.innerWidth;
    },
  },
  created() {},
  computed: {},
  mounted: function () {
    const routeInstance = this.$route;
    this.createTitleDesc(routeInstance);

    window.addEventListener("resize", this.calcWidth);
  },
  watch: {
    $route(routeInstance) {
      this.createTitleDesc(routeInstance);
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.calcWidth);
  },
  components: {
    Loading,
  },
};
</script>
<style scoped></style>
