<template>
  <v-container fluid>
    <v-row class="mt-0">
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-container fluid>
              <v-row no-gutters>
                <v-col cols="2">
                  <text-field
                    v-model="form.empNum"
                    class="red--text"
                    form-name="従業員番号"
                    clearable
                  />
                </v-col>
                <v-col cols="2">
                  <text-field
                    v-model="form.empName"
                    form-name="従業員名"
                    clearable
                  />
                </v-col>
                <v-btn
                  class="sub-form"
                  color="info"
                  outlined
                  @click="dialog.emp = true"
                  >検索</v-btn
                >
              </v-row>
              <v-row no-gutters>
                <div>
                  <text-field
                    v-model="form.deptCd"
                    form-name="部課CD"
                    clearable
                  />
                </div>
                <div>
                  <text-field
                    v-model="form.deptName"
                    form-name="部課名"
                    clearable
                  />
                </div>
                <div>
                  <v-btn
                    class="sub-form"
                    color="info"
                    outlined
                    @click="dialog.dept = true"
                    >検索</v-btn
                  >
                </div>
              </v-row>
              <v-row no-gutters>
                <v-col cols="2">
                  <text-field
                    v-model="form.password"
                    class="red--text"
                    form-name="パスワード"
                    password
                  />
                </v-col>
                <v-col cols="2">
                  <text-field
                    validateType="startWithZeroNumericHalf"
                    v-model="form.zeroNumeric"
                    form-name="数値のみ（0始まり許容）"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <div>
                  <select-component
                    v-model="form.div"
                    form-name="セレクト"
                    :items="testItem"
                  />
                </div>
                <div>
                  <autocomplete
                    v-model="form.ac"
                    form-name="オートコンプリート"
                    :items="testACList"
                  />
                </div>
              </v-row>
              <v-row no-gutters>
                <div>
                  <date-picker form-name="デイトピッカー" />
                </div>
                <div>
                  <date-time-picker form-name="デイトタイムピッカー" />
                </div>
              </v-row>
              <v-row no-gutters>
                <div>
                  <text-area form-name="テキストエリア" />
                </div>
              </v-row>
              <v-row no-gutters>
                <div>
                  <select-list form-name="セレクトリスト" :items="listItems" />
                </div>
              </v-row>
              <v-row no-gutters>
                <div>
                  <v-btn
                    class="sub-form"
                    color="info"
                    outlined
                    @click="docFileDialog = true"
                    >ファイル登録</v-btn
                  >
                </div>
                <v-col cols="2">
                  <text-field
                    required
                    v-model="docAttrVal"
                    form-name="TEST_VALUE"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <div>
                  <v-btn
                    class="sub-form"
                    color="info"
                    outlined
                    @click="docBrowse"
                    >ファイル閲覧</v-btn
                  >
                </div>
                <v-col cols="2">
                  <text-field
                    required
                    v-model="browseId"
                    number
                    form-name="DOC_FILE_ID"
                    clearable
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <doc-file-upload-dialog
      v-if="docFileDialog"
      :colbDocKindId="colbDocKindId"
      :colbDocAttrNam="colbDocAttrNam"
      :docAttrVal="docAttrVal"
      :colbContentsMenuInfoId="colbContentsMenuInfoId"
      :dialog="docFileDialog"
      @close="docFileDialog = false"
    />

    <!-- <emp-search-dialog
      v-if="dialog.emp"
      v-model="dialog.emp"
      @select="selectEmp"
    />
    <dept-search-dialog
      v-if="dialog.dept"
      v-model="dialog.dept"
      @select="selectDept"
    />-->
    <action-confirm ref="actionConfirm" />
    <!-- 全画面ローディングエリア -->
    <loading :active.sync="isLoading" :can-cancel="false" is-full-page />
  </v-container>
</template>

<script>
//import EmpSearchDialog from "tds-colb-cmns/src/components/common/dialog/EmployeeSearchDialog";
//import DeptSearchDialog from "tds-colb-cmns/src/components/common/dialog/DepartmentSearchDialog";
import Loading from "vue-loading-overlay";
import Autocomplete from "tds-colb-cmns/src/components/common/form/Autocomplete";
import TextField from "tds-colb-cmns/src/components/common/form/TextField";
import SelectList from "tds-colb-cmns/src/components/common/etc/SelectList";
import TextArea from "tds-colb-cmns/src/components/common/form/TextArea";
import SelectComponent from "tds-colb-cmns/src/components/common/form/Select";
import DatePicker from "tds-colb-cmns/src/components/common/picker/DatePicker";
import DateTimePicker from "tds-colb-cmns/src/components/common/picker/DateTimePicker";
import colbMixin from "tds-colb-cmns/src/mixins/colbMixin";
import DocFileUploadDialog from "tds-colb-cmns/src/components/doc/dialogs/DocFileUploadDialog";
import ActionConfirm from "tds-colb-cmns/src/components/common/dialog/ActionConfirm";
const rest = require("tds-colb-cmns/src/utils/api/restCmn");
import colbUri from "@/utils/api/uris/colb/index";

export default {
  name: "sample-one",
  model: {},
  mixins: [colbMixin],
  props: {},
  data: () => ({
    isLoading: false,
    form: {
      empNum: "",
      empName: "",
      deptCd: "",
      deptName: "",
      div: null,
      ac: null,
      password: "abcde",
      zeroNumeric: null,
    },
    dialog: {
      emp: false,
      dept: false,
    },
    testItem: [
      { id: 1, value: "ONE" },
      { id: 2, value: "TWO" },
    ],
    testACList: [
      { id: 1, value: "ABCDE" },
      { id: 2, value: "FGHIJ" },
      { id: 3, value: "KLMNO" },
      { id: 4, value: "PQRST" },
    ],
    listItems: [
      { no: 1, name: "001" },
      { no: 2, name: "002" },
      { no: 3, name: "003" },
    ],
    docFileDialog: false,
    callerSystemName: "デモ",
    colbDocAttrNam: "TEST_VALUE",
    docAttrVal: "XXYYppaa",
    colbDocKindId: 999,
    colbContentsMenuInfoId: null,
    browseId: null,
  }),
  methods: {
    selectEmp(val) {
      console.debug("select emp:", { ...val });
      this.$set(this.form, "empNum", val.empNo);
      this.$set(this.form, "empName", val.employeeName);
    },
    selectDept(val) {
      console.debug("select dept:", { ...val });
      this.$set(this.form, "deptCd", val.departmentNo);
      this.$set(this.form, "deptName", val.departmentName);
    },
    async docBrowse() {
      if (this.browseId == null) {
        this.$refs.actionConfirm.warningMsg({
          message: "ドキュメントIDを設定してください。",
        });
        return;
      }

      this.isLoading = true;

      await rest
        .get(colbUri.get_browse_file + "/" + this.browseId)
        .then((res) => {
          if (this.isExistValue(res.resultData)) {
            const browseUrl = res.resultData;
            this.isLoading = false;
            window.open(location.origin + browseUrl, "_blank");
          }
        })
        .catch((err) => {
          this.commonFail(err);
          this.isLoading = false;
          return;
        });
    },
    loading() {
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 3000);
    },
  },
  created() {},
  computed: {},
  watch: {},
  components: {
    Loading,
    //EmpSearchDialog,
    //DeptSearchDialog,
    Autocomplete,
    TextField,
    TextArea,
    SelectComponent,
    SelectList,
    DatePicker,
    DateTimePicker,
    DocFileUploadDialog,
    ActionConfirm,
  },
};
</script>
<style lang="sass" scoped>
.container
  > .row.row + .row
    margin-top: 8px
</style>
