<template>
  <v-container fluid :style="{ width: '100%', height: '100%' }">
    <v-row justify="center" no-gutters>
      <v-col :cols="col">
        <v-row justify="start" align="center" no-gutters>
          <v-col cols="12">
            <h3>v-data-table-v2</h3>
          </v-col>
        </v-row>
        <v-row align="center" no-gutters>
          <v-col cols="4" sm="2" lg="1">
            <v-select v-model="col" :items="cols" hide-details solo />
          </v-col>
          <v-col cols="4" sm="3" lg="2">
            <v-switch v-model="showSelect" label="show-select" hide-details />
          </v-col>
          <v-col cols="4" sm="3" lg="2">
            <v-switch
              v-model="singleSelect"
              label="single-select"
              hide-details
            />
          </v-col>
          <v-col cols="4" sm="3" lg="2">
            <v-checkbox v-model="colFixed" label="col-fixed" hide-details />
          </v-col>
          <v-col cols="4" sm="3" lg="2">
            <v-checkbox v-model="listTable" label="list-table" hide-details />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-data-table-v2
              class="elevation-1"
              :headers="headers"
              :items="items"
              :show-select="showSelect"
              :single-select="singleSelect"
              :list-table="listTable"
              item-key="name"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mt-3" no-gutters>
      <v-col cols="8">
        <v-row justify="start" align="center" no-gutters>
          <v-col cols="12">
            <h3>v-data-table</h3>
          </v-col>
          <v-col cols="12">
            <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="items"
              :show-select="showSelect"
              :single-select="singleSelect"
              item-key="name"
              height="587"
              fixed-header
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "sample-two",
  model: {},
  mixins: [],
  props: {},
  data() {
    return {
      col: "8",
      showSelect: false,
      singleSelect: false,
      colFixed: true,
      listTable: false,
      headers: [
        {
          text: "Dessert (100g serving)",
          align: "start",
          sortable: false,
          value: "name",
          width: 200,
          fixed: true,
        },
        {
          text: "Calories",
          value: "calories",
          width: 150,
          fixed: true,
        },
        { text: "Fat (g)", value: "fat", width: 150 },
        { text: "Carbs (g)", value: "carbs", width: 150 },
        { text: "Protein (g)", value: "protein", width: 150 },
        { text: "Iron (%)", value: "iron", width: 150 },
      ],
      items: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: "1%",
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: "1%",
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: "7%",
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: "8%",
        },
        {
          name: "Gingerbread",
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: "16%",
        },
        {
          name: "Jelly bean",
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: "0%",
        },
        {
          name: "Lollipop",
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: "2%",
        },
        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: "45%",
        },
        {
          name: "Donut",
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: "22%",
        },
        {
          name: "KitKat",
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: "6%",
        },
      ],
    };
  },
  methods: {},
  created() {
    const random = (max = 100) => Math.floor(Math.random() * max);
    Array.from({ length: 25 }, (_, i) =>
      this.items.push({
        name: `Marshmallow${i}`,
        calories: 133,
        fat: 0,
        carbs: 1.26,
        protein: 0.29,
        iron: `${random()}%`,
      })
    );
  },
  computed: {
    cols() {
      return Array.from({ length: 12 }, (_, i) => ({
        value: String(i + 1),
        text: `cols${i + 1}`,
      }));
    },
  },
  watch: {
    colFixed(val) {
      this.headers
        .filter((h) => h.fixed !== undefined)
        .forEach((h) => (h.fixed = val));
    },
  },
  components: {},
};
</script>
<style lang="sass" scoped>
@import "~vuetify/src/styles/styles"

$input-top-spacing: 0

.v-input--selection-controls
  margin-top: $input-top-spacing
  padding-top: 0
</style>
