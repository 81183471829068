/*協業型業務ポータル：共通処理*/
import UniversalCommon from "./UniversalCommon.vue";
import ValidationCommon from "./ValidationCommon.vue";
import FileSaver from "file-saver";
import { mapState, mapActions } from "vuex";
import { isEmpty, isEmptyObject } from "@/utils/ObjectUtils";
import { validationDate } from "@/utils/dateFormat";

export default {
  mixins: [UniversalCommon, ValidationCommon],
  methods: {
    errorMessage(err = {}) {
      if (err.resultData) {
        if (err.resultData[Symbol.toStringTag] === "ArrayBuffer") {
          this.arrayBufferErrorMessage(err);
          return;
        } else if (err.resultData[Symbol.toStringTag] === "Blob") {
          this.blobErrorMessage(err.resultData);
          return;
        }
      }
      const res = this.convertResponse(err);
      this.$refs.actionConfirm.generalMsg({
        message: res.message,
        details: res.fieldErrors.details,
      });
    },
    arrayBufferErrorMessage(err = {}) {
      let tmp = null;
      if (!err.resultData) {
        console.debug("エラーレスポンスの変換後:", { ...tmp });
      } else {
        const standardResponse = this.convertToStandardResponse(err.resultData);
        tmp = this.convertResponse(standardResponse);
      }
      this.$refs.actionConfirm.generalMsg({
        message: tmp.message,
        details: tmp.fieldErrors.details,
      });
    },
    blobErrorMessage(resultData = {}) {
      let reader = new FileReader();
      let tmp = null;
      reader.onload = () => {
        tmp = JSON.parse(reader.result);
        this.$refs.actionConfirm.generalMsg({
          message: tmp.message,
          details: tmp.fieldErrors ? tmp.fieldErrors.details : null,
        });
      };
      reader.readAsText(resultData);
    },
    /**
     * バリデーションエラーメッセージを表示する
     */
    validError() {
      this.$refs.actionConfirm.validationError({ action: "validationError" });
    },
    /**
     * 各操作の確認ダイアログを表示する
     *
     * @param {string} action 操作
     * @param {...any} args 引数
     */
    actionHandler(action = "", ...args) {
      this.$refs.actionConfirm.confirm({ action }).then((wait) => {
        if (wait) {
          if (this[action] !== undefined) {
            this[action](args);
          } else {
            console.error(`${action}が定義されていません`);
          }
        }
      });
    },
    /**
     * 画面遷移
     * @param {string} func
     * @param {*} query
     * @param {*} option
     */
    transition(navi = null, func = null, query = {}, option = {}) {
      const jobNavi = navi ? navi : this.$route.params.jobNavi;
      const routeObj = {
        name: "colbFrame",
        params: { jobNavi: jobNavi, jobFunc: func },
      };
      if (!isEmptyObject(query)) {
        routeObj.query = { ...query };
      }
      if (option) {
        routeObj.params = { ...routeObj.params, ...option };
      }
      console.debug("transition", routeObj);
      this.$router.push(routeObj);
    },
    /* formのクリア処理 */
    targetClear(...targets) {
      targets.forEach((key) => {
        if (this.form[key] !== undefined) {
          this.$set(this.form, key, null);
        } else {
          console.warn(`${key}はformに定義されていません`);
        }
      });
    },
    /* 設定の日付表示形式に対応した日付表示 */
    formatUserDate(val) {
      if (isEmpty(val)) return "";
      if (!validationDate(val)) return val;
      return this.formatDate(val, this.getDateFormats().date);
    },
    commonSuccess(actionName) {
      this.$refs.actionConfirm.success({
        message: `${actionName}を完了しました。`,
      });
    },
    commonFail(err) {
      console.error(err);
      this.errorMessage(err);
    },
    commonFinally() {
      this.isLoading = false;
    },
    commonSetLoading() {
      this.isLoading = true;
    },
    commonDownload(res, errMsg = "ダウンロードに失敗しました") {
      const fileName = this.getFileName(res.headers);
      const reg = /(.*)(?:\.([^.]+$))/;
      const file = new File([res.resultData], fileName, {
        type: fileName.match(reg)[2],
      });
      if (file.size == 0) {
        this.$refs.actionConfirm.generalMsg({
          message: errMsg,
          details: null,
        });
        return;
      }
      FileSaver.saveAs(file, fileName);
    },
    /* 期間内か判定 */
    inPeriod(startYmd = "", endYmd = "", extendsFlag = false) {
      const dateTime = new Date();
      const today = new Date(
        dateTime.getFullYear(),
        dateTime.getMonth(),
        dateTime.getDate()
      );
      let start;
      let end;
      if (startYmd) {
        start =
          startYmd.length > 10
            ? new Date(startYmd)
            : new Date(startYmd + " 00:00");
      }
      if (endYmd) {
        end =
          endYmd.length > 10 ? new Date(endYmd) : new Date(endYmd + " 00:00");
      }
      return start <= today && (today <= end || extendsFlag);
    },
    /** 指定した権限を保持有無。指定がない場合は必ずtrue */
    isMngrUser(allowedMngrDivs = []) {
      if (allowedMngrDivs.length == 0) {
        return true;
      }
      const mngrDivs = this.mngrRoles.managerDivSet
        ? this.mngrRoles.managerDivSet
        : [];
      const filter = (e) => mngrDivs.some((a) => a === e);
      return allowedMngrDivs.some(filter);
    },
    ...mapActions(["setLoading"]),
  },
  computed: {
    isLoading: {
      set(val) {
        this.setLoading(val);
      },
      get() {
        return this.$store.state.isLoading;
      },
    },
    ...mapState({
      mngrRoles: (state) => state.masterData.mngrRoles,
    }),
  },
};
