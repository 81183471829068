import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "@/views/common/NotFound.vue";
import Login from "@/views/common/Login.vue";
import otpLogin from "@/views/common/OtpLogin.vue";
import termsOfUse from "@/views/common/TermsOfUse.vue";
//import store from "@/store/index";
import { checkUserAuth } from "tds-colb-cmns/src/utils/auth/userAuth";
import SampleFrame from "@/views/sample/Home.vue";
import ColbFrame from "@/views/ColbFrame.vue";
//import funcNaviService from "@/services/funcNaviService";
import ColbContactView from "tds-colb-cmns/src/views/ColbContactView.vue";
import { APP_URL } from "@/utils/api/urlList";

// navigation duplicatedをエラー表示しない
const originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originPush.call(this, location).catch((err) => {
    if (err.name === "NavigationDuplicated")
      console.warn("遷移先パスが現在のパスと重複しています");
    else throw err;
  });
};

Vue.use(VueRouter);

// const basePath = "/tdsapl";
const baseColbPath = `${APP_URL}`;
//協業ポータルログイン画面パス
// const colbPortalLoginPath = `${APP_URL}/login`;

//お問い合わせページパス
const colbPortalContactPath = `${APP_URL}/contact`;

const routes = [
  {
    path: "*",
    name: "notFound",
    component: NotFound,
  },
  {
    meta: {
      title: "東芝インフラテクノサービス株式会社 協業型ポータル | ログイン",
      desc: "",
    },
    path: `${baseColbPath}/login`,
    name: "login",
    component: Login,
  },
  {
    meta: {
      title:
        "東芝インフラテクノサービス株式会社 協業型ポータル | ワンタイムパスワード",
      desc: "",
    },
    path: `${baseColbPath}/otplogin`,
    name: "otpLogin",
    component: otpLogin,
  },
  {
    meta: {
      title:
        "東芝インフラテクノサービス株式会社 協業型ポータル | 利用規約同意確認",
      desc: "",
    },
    path: `${baseColbPath}/termsofuse`,
    name: "termsOfUse",
    component: termsOfUse,
  },
  // {
  //   path: `${basePath}/dev/sample/:jobFunc`,
  //   name: "sampleFrame",
  //   component: SampleFrame,
  // },
  {
    meta: {
      title: "東芝インフラテクノサービス株式会社 協業型ポータル",
      desc: "",
    },
    path: `${baseColbPath}/:jobNavi/:jobFunc`,
    name: "colbFrame",
    component: ColbFrame,
  },
  {
    meta: {
      title: "東芝インフラテクノサービス株式会社 協業型ポータル | お問い合わせ",
      desc: "",
    },
    path: colbPortalContactPath,
    name: "ColbContactView",
    component: ColbContactView,
  },
  // 開発用暫定ページ
  {
    meta: { title: "Coperni+x", desc: "" },
    path: `${baseColbPath}/top`,
    name: "colbFrameTop",
    component: ColbFrame,
  },
  // 開発用暫定ページ
  {
    meta: { title: "Coperni+x:サンプル", desc: "" },
    path: `${baseColbPath}/sample`,
    name: "colbFrameSample",
    component: SampleFrame,
  },
  // 開発用暫定リダイレクト
  // { path: "", redirect: `${baseColbPath}/top` },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log("to, from, next", to, from, next);
  //TO 行先の画面
  //FROM 遷移元の画面
  //NEXT function

  // 認証情報チェック
  if (await checkUserAuth(to)) next();
});

export default router;
