/** 従業員リスト */
export const EmployeeListGetRequestModel = Object.freeze({
  departCd: "",
  empNum: "",
  name: "",
});

/** 従業員リスト：取得内容 */
export const EmployeeListItemGetResponseModel = Object.freeze({
  /** 従業員番号 */
  empNum: "",
  /** 氏名 */
  name: "",
  /** 部課CD */
  departCd: "",
  /** 部課名 */
  abbrv2: "",
  /** メールアドレス */
  emailAddress: "",
  /** TEL */
  departTel: "",
});

export const SelectBoxModel = Object.freeze({
  id: "",
  value: "",
});

export const ManagerDiv = Object.freeze({
  /** システム管理者 */
  SYSTEM: "SYSTEM",
  /** 部門管理者 */
  BUMON: "BUMON",
  /** 技術管理者 */
  TECHNIQUE: "TECHNIQUE",
  /** 参照管理者 */
  REFERENCE: "REFERENCE",
});

export const ColbAdminRole = Object.freeze({
  /** システム管理者 */
  ADMIN: "ADMIN",
  /** SS管理者 */
  SS_ADMIN: "SS_ADMIN",
  /** 部門管理者 */
  DEPT_ADMIN: "DEPT_ADMIN",
  /** 利用申請管理者 */
  APLY_ADMIN: "APLY_ADMIN",
});
