// import { ColbAdminRole } from "@/models/masterDataModels";
import store from "@/store/index";
/**
 * FuncNavigationに表示するアイテムを定義
 * 各キーはrouterのjobNaviに対応し、各要素のkeyがjobFuncに対応する
 */
const navs = {
  sample: [
    {
      key: "one",
      icon: "fas fa-dice-one",
      titleMini: "サンプル1",
      title: "サンプルページ1",
      clicked: false,
      bikkuriMark: false,
      disabled: false,
      default: true,
    },
    {
      key: "two",
      icon: "fas fa-dice-two",
      titleMini: "サンプル2",
      title: "サンプルページ2",
      clicked: false,
      bikkuriMark: false,
      disabled: false,
    },
    {
      key: "three",
      icon: "fas fa-dice-three",
      titleMini: "サンプル3",
      title: "サンプルページ3",
      clicked: false,
      bikkuriMark: false,
      disabled: false,
    },
  ],
  home: [
    {
      key: "",
      icon: "fas fa-dice-one",
      titleMini: "top",
      title: "top",
      clicked: false,
      bikkuriMark: false,
      disabled: false,
      default: true,
    },
  ],
};

function isNotAccessible(nav) {
  // navとログインユーザの権限チェック
  const allowedMngrDivs = nav.mngrDivs ? nav.mngrDivs : [];
  // navに権限の設定がない場合はアクセス可
  if (allowedMngrDivs.length == 0 && !nav.crtUsrMenu) {
    return false;
  }
  // ログイン者の権限情報を取得していない場合はアクセス不可
  if (!store.state.masterData || !store.state.masterData.mngrRoles) {
    return true;
  }
  // ログイン者の権限情報とnav管理者権限を突合
  const mngrDivSet = store.state.masterData.mngrRoles.managerDivSet
    ? store.state.masterData.mngrRoles.managerDivSet
    : [];
  const filter = (e) => mngrDivSet.some((a) => a === e);
  if (allowedMngrDivs.some(filter)) {
    return false;
  }
  return true;
}

export function getFuncNaviMenu(jobNavi = null) {
  if (navs[jobNavi] !== undefined) {
    const navList = navs[jobNavi];
    for (const nav of navList) {
      let disabled = isNotAccessible(nav);
      nav.disabled = disabled;
    }
    return navList;
  } else {
    console.warn(`${jobNavi}は定義されていません`);
  }
}

// リダイレクト先画面
// TODO ホームのURLが決まったら設定
const defaultRedirect = { params: { jobNavi: "home", jobFunc: "" } };

function redirectTest({ name, params = { jobNavi: "", jobFunc: "" } } = {}) {
  const to = navs[params.jobNavi].find((e) => e.key == params.jobFunc);
  if (to && isNotAccessible(to)) {
    let redirect = { redirect: true, name: name, ...defaultRedirect };
    return redirect;
  } else {
    return { redirect: false, name: name, params: params };
  }
}

export default {
  redirectTest,
};
