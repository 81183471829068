<template>
  <div>
    <header-bar />
    <v-main>
      <div id="sample-home">
        <v-container fluid>
          <v-row no-gutters>
            <div class="functionNavigation mini">
              <func-nav @change="changeNav" />
            </div>
            <v-col class="eng-contents-main">
              <sample-one v-if="display.one" />
              <sample-two v-if="display.two" />
              <v-card v-if="display.three" height="100%" width="100%" />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-main>
    <footer-bar />
  </div>
</template>

<script>
import HeaderBar from "tds-colb-cmns/src/components/common/HeaderBar";
import FooterBar from "tds-colb-cmns/src/components/common/FooterBar";
// import HeaderBar from "@/components/common/HeaderBar";
// import FooterBar from "@/components/common/FooterBar";
import FuncNav from "@/components/common/nav/FunctionNavigation";
import SampleOne from "./SampleOne";
import SampleTwo from "./SampleTwo";
import { getFuncNaviMenu } from "@/services/funcNaviService";
import { mapActions } from "vuex";

export default {
  name: "eng-home-compo",
  model: {},
  mixins: [],
  props: {},
  data: () => ({
    display: {
      one: true,
      two: false,
      three: false
    }
  }),
  methods: {
    changeNav(key) {
      Object.keys(this.display).forEach(disp => {
        if (disp === key) this.$set(this.display, disp, true);
        else this.$set(this.display, disp, false);
      });
    },
    ...mapActions("funcNavi", ["setFuncNaviMenu"])
  },
  created() {
    this.setFuncNaviMenu(getFuncNaviMenu("sample"));
    const { jobFunc } = this.$route.params;
    if (this.display[jobFunc] !== undefined) {
      Object.keys(this.display).forEach(key => {
        if (key === jobFunc) this.$set(this.display, key, true);
        else this.$set(this.display, key, false);
      });
    }
  },
  computed: {},
  watch: {},
  components: {
    HeaderBar,
    FooterBar,
    FuncNav,
    SampleOne,
    SampleTwo
  }
};
</script>
<style lang="sass" scoped>
#sample-home
  height: calc(100vh - 48px - 21px)
  overflow: auto

  > .container
    padding: 0
    margin: 0
    height: 100%
    max-width: 100%
    > .row
      height: 100%
</style>
