"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.default = {
  dataIterator: {
    itemsPerPageText: "表示件数:",
    itemsPerPageAll: "すべて",
    pageText: "{0}～{1}件目 / {2}件中",
    noResultsText: "検索結果が見つかりません",
    nextPage: "次のページ",
    prevPage: "前のページ",
  },
  dataTable: {
    itemsPerPageText: "表示件数:",
    ariaLabel: {
      sortDescending: "降順の並び替え。",
      sortAscending: "昇順の並び替え。",
      sortNone: "ソートされていません。",
      activateNone: "ソートを削除するには有効にしてください。",
      activateDescending: "降順の並び替えのためには有効にしてください。",
      activateAscending: "昇順のソートのためには有効にしてください。",
    },
    sortBy: "ソート方式",
  },
  dataFooter: {
    itemsPerPageText: "1ページあたりの件数：",
    itemsPerPageAll: "すべて",
    nextPage: "次のページ",
    prevPage: "前のページ",
    firstPage: "最初のページ",
    lastPage: "最後のページ",
    pageText: "{0}～{1}件目 / {2}件中",
  },
  pagination: {
    ariaLabel: {
      wrapper: "ページネーションナビゲーション",
      next: "次のページ",
      previous: "前のページ",
      page: "{0}ページ目に移動",
      currentPage: "現在のページ、ページ {0}",
    },
  },
  noDataText: "データがありません",
  carousel: {
    prev: "前の画像",
    next: "次の画像",
  },
};
