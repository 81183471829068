<template>
  <!-- 協業ポータル -->
  <div>
    <header-bar
      ref="ref_header"
      :portletRefs="myPrtlPrtltRefs"
      @switchSideBar="sideBarArea"
      systemName
      :homePath="colbBasePath + '/top'"
      :colbBasePath="colbBasePath"
      @switchOpenContentsMenu="switchFuncNavdrawer"
    />
    <v-main>
      <v-container fluid>
        <v-row no-gutters>
          <div class="functionNavigation mini">
            <contents-menu @change="displayContents" :drawer="funcNavdrawer" />
          </div>
          <v-col class="colb-contents">
            <notice v-if="display.top" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <footer-bar />
  </div>
</template>

<script>
// 共通
import HeaderBar from "tds-colb-cmns/src/components/common/HeaderBar";
import FooterBar from "tds-colb-cmns/src/components/common/FooterBar";
import ContentsMenu from "@/components/tdsColb/nav/ContentsMenu";
// トップ画面用
import Notice from "tds-colb-cmns/src/components/portal/contents/Notice.vue";

import colbMixin from "@/mixins/colbMixin";
import store from "@/store/index";

import { APP_URL } from "@/utils/api/urlList";

export default {
  name: "colb-mng-frame",
  model: {},
  mixins: [colbMixin],
  props: {},
  data: () => ({
    display: {
      // common: true,
      top: true,
    },
    myPrtlPrtltRefs: null,
    funcNavdrawer: true,
  }),
  methods: {
    /** サイドバー表示時にコンテンツ位置を調整する */
    sideBarArea(sideBarWidth) {
      // ボディ部分にサイドバーエリアを作成
      let $ref = this.$refs.portalBody;
      $ref.$el.style.paddingRight = sideBarWidth;
      // フッタ部分にサイドバーエリアを作成
      $ref = this.$refs.ref_footer;
      $ref.sideBarArea(sideBarWidth);
    },
    switchFuncNavdrawer() {
      this.funcNavdrawer = !this.funcNavdrawer;
    },
    displayContents(key = null, defaultFunc = null) {
      const { jobNavi, jobFunc } = this.$route.params;
      Object.keys(this.display).forEach((k) => {
        this.display[k] = this.display[key] !== undefined && key === k;
      });
      //開発用暫定対応
      if (key == null || key == undefined) {
        this.display.top = true;
        return;
      }
      if (jobNavi !== key || jobFunc !== defaultFunc) {
        this.switchFuncNavdrawer(!this.funcNavdrawer);
        this.$router.push({
          name: "colbFrame",
          params: { jobNavi: key, jobFunc: defaultFunc },
        });
      }
    },
  },
  created() {
    this.$nextTick(() => {
      const { jobNavi, jobFunc } = this.$route.params;
      this.displayContents(jobNavi, jobFunc);
    });
  },
  beforeRouteEnter(to, from, next) {
    //fetchData
    store.dispatch("colb/setDataAll", to, from).finally(() => {
      next();
    });
  },
  beforeRouteUpdate(to, from, next) {
    //fetchData
    store
      .dispatch("colb/setDataAll", to)
      .then(() => {
        // // 目標承認・評価から状況一覧以外に移動する場合、ストアリセット
        // if (
        //   !(
        //     from.path.endsWith("goalApprv") && to.path.endsWith("registState")
        //   ) &&
        //   !(from.path.endsWith("goalApprv") && to.path.endsWith("goalApprv")) &&
        //   !(from.path.endsWith("registState") && to.path.endsWith("goalApprv"))
        // ) {
        //   store.dispatch("colb/resetRegistStateListState");
        // }
      })
      .finally(() => {
        next();
      });
  },
  computed: {
    tabList() {
      // 上部タブメニューの表示制御
      const mngrDivs = this.mngrRoles.managerDivSet
        ? this.mngrRoles.managerDivSet
        : [];
      const filter = (e) =>
        !e.mngrDivs || mngrDivs.some((a) => e.mngrDivs[0] === a);
      return this.topicPath.filter(filter);
    },
    colbBasePath() {
      return APP_URL;
    },
  },
  watch: {},
  components: {
    HeaderBar,
    FooterBar,
    ContentsMenu,
    // Common,
    Notice,
  },
};
</script>
