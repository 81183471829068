<template>
  <div>
    <confirm
      :dialog="commonConfirm"
      :confirmTitle="confirmSettings.confirmTitle"
      :confirmText="confirmSettings.confirmText"
      :btns="confirmSettings.btns"
      :settingWidth="width"
      @pushBtn="closeConfirm($event)"
    />
  </div>
</template>
<script>
const cmnMsg = require("@/utils/message/commonMessage");
import Confirm from "@/components/common/dialog/Confirm";

export default {
  props: {
    //一時的な追加ラベル
    actions: {
      type: Object,
    },
  },
  data: () => ({
    commonConfirm: false,
    confirmSettings: {
      confirmTitle: "",
      confirmText: "",
      btns: [
        {
          title: "",
          value: "",
          color: "",
          text: false,
          type: "",
        },
        {
          title: "",
          value: "",
          color: "",
          text: false,
          type: "",
        },
      ],
    },

    //ボタン操作の結果のPromiseを保持
    promise: {
      resolve: () => {},
    },

    width: 400,
  }),
  methods: {
    /** 操作Confirm表示
     * @return ボタン操作の応答のPromise
     */
    confirm({ action, message, width }) {
      this.confirmSettings = this.defaultConfirmSetting;
      this.setConfirmText("CONFIRM", { action, message });
      // サイズ指定がある場合はそれをセット
      if (width) this.width = width;
      return this.openConfirm();
    },
    /** 操作成功Confirm表示
     * @return ボタン操作の応答のPromise
     */
    success({ action, code, message, width }) {
      this.confirmSettings = this.defaultSuccessConfirmSetting;
      this.setConfirmText("SUCCESS", { action, code, message });
      // サイズ指定がある場合はそれをセット
      if (width) this.width = width;
      return this.openConfirm();
    },
    /** 操作失敗Confirm表示
     * @return ボタン操作の応答のPromise
     */
    failure({ action, code, message, width }) {
      this.confirmSettings = this.defaultFailureConfirmSetting;
      this.setConfirmText("FAILURE", { action, code, message });
      // サイズ指定がある場合はそれをセット
      if (width) this.width = width;
      return this.openConfirm();
    },
    /** 操作失敗Details Confirm表示
     * @return ボタン操作の応答のPromise
     */
    // failureDetails({action, code, details}) {
    //   this.confirmSettings = this.defaultFailureConfirmSetting;
    //   this.setConfirmText("FAILURE_DETAILS", {action, code, details});
    //   return this.openConfirm();
    // },
    validationError({ action, code, message, width }) {
      this.confirmSettings = this.defaultValidationErrorConfirmSetting;
      this.setConfirmText("VALIDATION_ERROR", { action, code, message });
      // サイズ指定がある場合はそれをセット
      if (width) this.width = width;
      return this.openConfirm();
    },
    /* API側からのメッセージを出力する際や、汎用的にメッセージを画面側で出したい場合に使用 */
    /* message または message + '\n\n' + details[0] + '\n' ... + '\n' + details[length-1]] */
    generalMsg({ action, code, message, details, width }) {
      this.confirmSettings = this.defaultFailureConfirmSetting;
      this.confirmSettings.confirmTitle = "エラー";
      this.setConfirmText(null, {
        action: action,
        code: code,
        message: message,
        details: details,
      });
      // サイズ指定がある場合はそれをセット
      if (width) this.width = width;
      return this.openConfirm();
    },

    // 警告メッセージダイアログ
    warningMsg({ action, code, message, details, width }) {
      this.confirmSettings = this.defaultFailureConfirmSetting;
      this.confirmSettings.confirmTitle = "メッセージ";
      this.setConfirmText(null, {
        action: action,
        code: code,
        message: message,
        details: details,
      });
      // サイズ指定がある場合はそれをセット
      if (width) this.width = width;
      return this.openConfirm();
    },

    //mode: "CONFIRM", "SUCCESS", "FAILURE"
    // 優先度1 => message設定 : 最優先でmessageの文章が優先され、detailsがあれば詳細が下部に表示される
    // 優先度2 => code設定 : code設定時はactionが必須, code, actionから生成したメッセージと、detailsがあれば詳細が下部に表示される
    // 優先度3 => mode設定 : message、codeに設定がない場合はmodeによる判定が実施される。
    // 設定が誤っている場合 => 優先度3によりNo Messageが取得され表示される
    setConfirmText(mode, { action, code, message, details }) {
      let msg = "";
      if (message) {
        msg = message;
        if (details) {
          const detailsMsg = Object.values(details)
            .map((item) => item.message)
            .join("\n");
          msg += "\n\n" + detailsMsg;
        }
      } else if (code) {
        // console.warn("action %s, details %s", action, details);
        // msg = this.getMsg(code, action, null);
        msg = this.getMsg(code, action);
        if (details) {
          const detailsMsg = Object.values(details)
            .map((item) => item.message)
            .join("\n");
          msg += "\n\n" + detailsMsg;
        }
      } else {
        let msgCode = "";
        switch (mode) {
          case "CONFIRM":
            msgCode = "I_CONFIRM";
            break;
          case "SUCCESS":
            msgCode = "I_COMPLETE";
            break;
          case "FAILURE":
            msgCode = "E_UNEXPECTED";
            break;
          case "FAILURE_DETAILS":
            msgCode = "E_FAILED_DETAILS";
            break;
          case "VALIDATION_ERROR":
            msgCode = "E_VALIDATION_ERROR";
            break;
        }
        // msg = this.getMsg(msgCode, action, details);
        msg = this.getMsg(msgCode, action);
      }
      this.confirmSettings.confirmText = msg;
    },

    customConfirm(setting) {
      this.confirmSettings = setting;
      return this.openConfirm();
    },

    openConfirm() {
      this.commonConfirm = true;
      return new Promise((resolve) => {
        this.promise.resolve = resolve;
      });
    },

    // Confirmのボタン処理
    closeConfirm(btnAction) {
      this.commonConfirm = false;
      // サイズを標準に戻す
      this.width = 400;
      this.promise.resolve(btnAction);
    },

    getLabel(action) {
      let dispName = this.labels[action];
      if (!dispName) dispName = action;
      return dispName;
    },

    //     getMsg(code, action, details) {
    getMsg(code, action) {
      switch (code) {
        case "I_CONFIRM":
          return cmnMsg.getMessage("CMN_I_0006", { 0: this.getLabel(action) });
        case "I_COMPLETE":
          return cmnMsg.getMessage("CMN_I_0001", { 0: this.getLabel(action) });

        case "E_PLEASE_FILL":
          return cmnMsg.getMessage("CMN_E_0007", {
            0: this.getLabel(action),
            1: this.getLabel("input"),
          });
        case "E_UNEXPECTED":
          return cmnMsg.getMessage("CMN_E_0003", { 0: this.getLabel(action) });
        case "E_FAILED":
          return cmnMsg.getMessage("CMN_E_0008", { 0: this.getLabel(action) });
        case "E_NOT_EXIST":
          return cmnMsg.getMessage("CMN_E_0009", { 0: this.getLabel(action) });
        case "E_VALIDATION_ERROR":
          return cmnMsg.getMessage("CMN_E_0013", { 0: this.getLabel(action) });
        // case "E_FAILED_DETAILS":
        //   return cmnMsg.getMessage("CMN_E_0014", {0: this.getLabel(action), 1: details})
        default:
          return "No messsage";
      }
    },
  },
  computed: {
    defaultConfirmSetting() {
      return {
        confirmTitle: cmnMsg.getMessage("CMN_D_0001"),
        confirmText: "",
        btns: [
          {
            title: cmnMsg.getMessage("CMN_B_0002"),
            value: true,
            color: "success",
            text: false,
          },
          {
            title: cmnMsg.getMessage("CMN_B_0003"),
            value: false,
            color: "secondary",
            text: false,
          },
        ],
      };
    },
    defaultSuccessConfirmSetting() {
      return {
        confirmTitle: cmnMsg.getMessage("CMN_D_0001"),
        confirmText: "",
        btns: [
          {
            title: cmnMsg.getMessage("CMN_B_0001"),
            value: true,
            color: "success",
            text: false,
          },
        ],
      };
    },
    defaultFailureConfirmSetting() {
      return {
        confirmTitle: cmnMsg.getMessage("CMN_D_0002"),
        confirmText: "",
        btns: [
          {
            title: cmnMsg.getMessage("CMN_B_0001"),
            value: false,
            color: "success",
            text: false,
          },
        ],
      };
    },
    defaultValidationErrorConfirmSetting() {
      return {
        confirmTitle: cmnMsg.getMessage("CMN_D_0002"),
        confirmText: "",
        btns: [
          {
            title: cmnMsg.getMessage("CMN_B_0001"),
            value: false,
            color: "success",
            text: false,
            type: "submit",
          },
        ],
      };
    },
    labels() {
      return {
        update: cmnMsg.getMessage("CMN_L_0001"),
        delete: cmnMsg.getMessage("CMN_L_0002"),
        regist: cmnMsg.getMessage("CMN_L_0013"),
        cancel: cmnMsg.getMessage("CMN_L_0014"), //取消
        search: cmnMsg.getMessage("CMN_L_0020"),
        failure: cmnMsg.getMessage("JOB_L_C0016"),
        get: cmnMsg.getMessage("CMN_L_0007"),
        copy: cmnMsg.getMessage("JOB_L_C0001"),
        renew: cmnMsg.getMessage("JOB_L_C0023"),

        deleteFile: cmnMsg.getMessage("CMN_L_0024"),
        //TODO: キー名が"取消"と重複しているため要変更
        // cancel: cmnMsg.getMessage("JOB_L_C0003"),
        approve: cmnMsg.getMessage("JOB_L_C0004"),
        remand: cmnMsg.getMessage("JOB_L_C0005"),
        denial: cmnMsg.getMessage("JOB_L_C0022"),
        sort: cmnMsg.getMessage("JOB_L_C0006"),
        correct: cmnMsg.getMessage("JOB_L_C0007"),
        issue: cmnMsg.getMessage("JOB_L_C0011"),
        blankIssue: cmnMsg.getMessage("JOB_L_C0012"),
        reuse: cmnMsg.getMessage("JOB_L_C0015"),
        unapprove: cmnMsg.getMessage("JOB_I_0003"),

        formPrint: cmnMsg.getMessage("JOB_L_J0251"),

        apprvApply: cmnMsg.getMessage("JOB_L_H0201"),
        salesApply: "売上申請",
        salesRevApply: "売上訂正",
        salesCxlApply: "売上取消",

        applyAndBugaiReq:
          cmnMsg.getMessage("JOB_L_H0201") +
          "と" +
          cmnMsg.getMessage("JOB_L_H0251"),

        apply: cmnMsg.getMessage("JOB_L_C0002"),
        cancelWf: cmnMsg.getMessage("JOB_L_C0003"), //引戻

        validationError: "CMN_E_0013",

        qualityRecord: "品質記録",
        estReq: "見積依頼",
        clear: "画面をクリア",
        cancelEstAnsRelation: "見積回答連携解除",
        tempSave: "一時保存",
        clone: cmnMsg.getMessage("JOB_L_C0001"),
        logout: cmnMsg.getMessage("CMN_I_1002"),
        ...this.actions,
      };
    },
  },
  components: {
    Confirm,
  },
};
</script>
