/**
 *
 * ダイアログに表示する内容一覧
 * 共通的に使えそうなものがあれば随時追加！
 *
 */

// タイトル用
export const messageTitle = {
  // タイトル
  CONFIRM_TITLE: "確認",
  COMPLTE_TITLE: "完了",
  ERROR_TITLE: "エラー",
  WARNING_TITLE: "警告"
};

// ダイアログの中身用
// タイトル用
export const dialogMessage = {
  // タイトル
  SAVE_COMPLETE: "保存しました",
  APPROVE_COMPLETE: "承認しました"
};

// メッセージ ここにメッセージを追加していく
// CMN=共通、PTL=ポータル
// D=ダイアログのタイトルなど B=ボタン I=インフォメッセージ E=エラーメッセージ
// M=システムメッセージ
export const messages = {
  CMN_M_0001: "システム処理中のため、業務は行えません。",

  CMN_D_0001: "確認",
  CMN_D_0002: "エラー",
  CMN_D_0003: "メッセージ",

  CMN_B_0001: "確認",
  CMN_B_0002: "はい",
  CMN_B_0003: "いいえ",

  CMN_L_0001: "更新",
  CMN_L_0002: "削除",
  CMN_L_0003: "行",
  CMN_L_0004: "コメント",
  CMN_L_0005: "スレッド",
  CMN_L_0006: "処理",
  CMN_L_0007: "取得",
  CMN_L_0008: "投稿",
  CMN_L_0009: "表示",
  CMN_L_0010: "ポートレット",
  CMN_L_0011: "使用中のブラウザ",
  CMN_L_0012: "アラーム通知",
  CMN_L_0013: "登録",
  CMN_L_0014: "取消",
  CMN_L_0015: "選択確定",
  CMN_L_0016: "チェックボックス",
  CMN_L_0017: "1つ以上選択",
  CMN_L_0018: "検索条件",
  CMN_L_0019: "設定",
  CMN_L_0020: "検索",
  CMN_L_0021: "選択",
  CMN_L_0022: "入力",
  CMN_L_0023: "連携",
  CMN_L_0024: "ファイル削除",
  CMN_L_0025: "引合NO",
  CMN_L_0026: "受注NO",
  CMN_L_0027: "注番略番",
  CMN_L_0028: "製番略番",
  CMN_L_0029: "閉じる",
  CMN_L_0030: "受領",

  CMN_I_0001: "{0}が完了しました。",
  CMN_I_0002: "{0}を保存しますか？",
  CMN_I_0003: "入力内容が失われます。\n閉じてもよろしいですか？",
  CMN_I_0004: "削除します。\nよろしいでしょうか。",
  CMN_I_0005: "{0}を作成しますか？",
  CMN_I_0006: "{0}を実行します。\nよろしいですか？",
  CMN_I_0007: "{0}に成功しました。",
  CMN_I_0008: "{0}します。\nよろしいですか？",

  CMN_E_0001: "{0}に失敗しました。\n指定された{1}は別の端末で削除済みです。",
  CMN_E_0002:
    "{0}に失敗しました。\n別の端末で{1}済みの{2}を含んでいます。\n再度ご確認ください。",
  // CMN_E_0003: "{0}に失敗しました。\nシステム管理者に問い合わせてください",
  CMN_E_0003: "{0}に失敗しました。",
  CMN_E_0004: "指定した{0}は{1}ではありません。",
  CMN_E_0005: "{0}は{1}に非対応です。",
  CMN_E_0006: "{0}を実行するには{1}を{2}してください。",
  CMN_E_0007: "{0}を{1}してください。",
  CMN_E_0008: "{0}に失敗しました。",
  CMN_E_0009: "該当する{0}がありません。",
  CMN_E_0010: "{0}された{1}は存在しません。",
  CMN_E_0011: "{0}された{1}は既に{2}済です。",
  CMN_E_0012: "{0}が採番されていせん。",
  CMN_E_0013: "入力された値が正しくありません。\n入力内容を再度確認の上実行してください。",
  CMN_E_0014: "{0}に失敗しました。\n\n{1}",

  // 認証周り用
  CMN_I_1001: "認証情報が確認できませんでした。\n認証情報の再取得を行います。",
  CMN_I_1002: "ログアウトします。\nよろしいでしょうか？",
  CMN_I_1003: "閲覧権限がありません。\nトップページに戻ります。",
  CMN_I_1004: "登録権限がありません。",

  // web上のサンプル
  CMN_I_9999:
    "IEで使えない{0}を控えてたら、{1}とか{2}（{3}）が台頭してきてみんな使ってるし、すごい置いて行かれている気持ち",

  PTL_L_0001: "(1)マイポートレット\n(2)固定ポータル\n(3)ポータル雛形",
  PTL_L_0002: "編集対象のポートレット",

  PTL_I_0001: "お知らせが掲載されました。",
  PTL_E_0001: "{0}[{1}: {2}に失敗しました。]",
  PTL_E_0002: "{0}[{1}: {2}中に予期せぬ問題が発生しました。]",
  PTL_E_0003:
    "{0}に失敗しました。\n指定された{1}は以下の何れかに設定されています。\n{2}",
  PTL_E_0004:
    "{0}に失敗しました。\n当該{1}は{2}されているため{3}することができません。",

  PRC_E_0001: "{0}[{1}: {2}に失敗しました。]",
  PRC_E_0002: "{0}[{1}: {2}中に予期せぬ問題が発生しました。]",
  PRC_I_0001: "{2}が登録されました",
  PRC_I_0002: "選択した{0}を{1}しますか？",

  JOB_L_C0001: "複写",
  JOB_L_C0002: "申請",
  JOB_L_C0003: "引戻",
  JOB_L_C0004: "承認",
  JOB_L_C0005: "差戻",
  JOB_L_C0006: "並べ替え",
  JOB_L_C0007: "訂正",
  JOB_L_C0008: "印刷",
  JOB_L_C0009: "作業員",
  JOB_L_C0010: "作業員日程登録",
  JOB_L_C0011: "発行",
  JOB_L_C0012: "空白発行",
  JOB_L_C0013: "用紙",
  JOB_L_C0014: "日程",
  JOB_L_C0015: "再利用",
  JOB_L_C0016: "失注",
  JOB_L_C0017: "製番略番",
  JOB_L_C0018: "製番",
  JOB_L_C0019: "ジョブフロー情報",
  JOB_L_C0020: "サービス番号",
  JOB_L_C0021: "見積依頼",
  JOB_L_C0022: "否認",
  JOB_L_C0023: "引合展開",

  JOB_L_I0001: "引合",
  JOB_L_I0101: "引合情報",
  JOB_L_I0151: "帳票出力",
  JOB_L_I0201: "受注情報",

  JOB_L_J0001: "受注",
  JOB_L_J0101: "契約情報",
  JOB_L_J0151: "受注決裁",
  JOB_L_J0201: "請求管理",
  JOB_L_J0251: "帳票出力",
  JOB_L_J0301: "発番管理",

  JOB_L_H0001: "発番",
  JOB_L_H0101: "製番情報",
  JOB_L_H0151: "作業情報",
  JOB_L_H0201: "承認申請",
  JOB_L_H0251: "部外依頼",
  JOB_L_H0301: "売上管理",
  JOB_L_H0351: "帳票印刷",
  JOB_L_H0401: "手配一覧",

  JOB_E_0001: "{0}で使用されているため、{1}できません。",
  JOB_I_0001: "{0}を行ってください。\n画面を終了する場合は、{1}ボタンを押してください。",
  JOB_I_0002: "決裁を解除します。\nよろしいですか？",
  JOB_I_0003: "決裁が解除されました。\n情報を修正し、再度申請してください。",

  JOB_E_H0001: "エビデンスが揃っていないため、申請できません。",
  JOB_E_H0002: "受注、発番の金額及び数量が不整合のため、申請できません。",

  JOB_I_T0001: "エビデンスが揃っていませんが、完成しますか？",

  JOB_E_R0001: "ワークフロー申請中のため更新できません。",
};

// 以下のように画面側では使う。
// const msg = require("@/utils/message/commonMessage");
// msg.getMessage('CMN_I_9999', {0:'JavaScript', 1:'node.js', 2:'TypeScript', 3:'AltJS'}); => 埋め込まれた文字列
// msg.getMessage('CMN_I_9999'); => 埋め込まれないそのままの文字列

export function getMessage(code, object) {
  var msg = formatByObj(messages[code], object);
  //console.log(msg);
  return msg;
}

export function formatByObj(msg, obj) {
  // objが定義されてない場合はそのままメッセージを返す
  if (obj === undefined || obj === null) {
    return msg;
  }
  return msg.replace(/\{(\w+)\}/g, function(m, k) {
    return obj[k];
  });
}
