export const uriList = {
  // /* 認証情報系 */
  // ptl_authuser_detail: "/portal/api/authuser/detail",

  // /* Sphin+x システムステータス取得 */
  // sphinx_system_status_get: "tdsjob/api/jobcommon/systemStatus/get",

  // /* 汎用マスタ系 */
  // ptl_cmnMst_dateFormatList: "/portal/api/cmnMst/dateFormatList",
  // ptl_cmnMst_extPrtltTypeList: "/portal/api/cmnMst/extPrtltTypeList",
  // ptl_cmnMst_fixPrtlNoList: "/portal/api/cmnMst/fixPrtlNoList",
  // ptl_cmnMst_fontSizeList: "/portal/api/cmnMst/fontSizeList",
  // ptl_cmnMst_helpViewNoList: "/portal/api/cmnMst/helpViewNoList",
  // ptl_cmnMst_htmlContTypeList: "/portal/api/cmnMst/htmlContTypeList",
  // ptl_cmnMst_infoTypeList: "/portal/api/cmnMst/infoTypeList",
  // ptl_cmnMst_linkTypeList: "/portal/api/cmnMst/linkTypeList",
  // ptl_cmnMst_noticeTimingList: "/portal/api/cmnMst/noticeTimingList",
  // ptl_cmnMst_opnRngDivList: "/portal/api/cmnMst/opnRngDivList",
  // ptl_cmnMst_portletList: "/portal/api/cmnMst/portletList",
  // ptl_cmnMst_refreshIntervalList: "/portal/api/cmnMst/refreshIntervalList",
  // ptl_cmnMst_stdPrtltList: "/portal/api/cmnMst/stdPrtltList",

  /* JOB管理-共通 */
  job_cmn: "tdsjob/api/jobcommon",
  job_cmn_agency_list: "tdsjob/api/jobcommon/AgencySearch/get/agencyList/",
  job_cmn_businessCategory_list:
    "tdsjob/api/jobcommon/businessCategorySelect/list/salesDiv/",
  job_cmn_channelsDiv_list:
    "tdsjob/api/jobcommon/channelsDivSearch/get/channelsDivList/",
  job_cmn_contactOrder_list:
    "tdsjob/api/jobcommon/contactOrderSearch/get/contactOrderList/",
  job_cmn_contactOrder_get:
    "tdsjob/api/jobcommon/contactOrderSearch/get/contactOrder/", // 発注先取得
  job_cmn_delvCnd_list: "tdsjob/api/jobcommon/delvCndSearch/get/delvCndList/",
  job_cmn_department_base_List:
    "tdsjob/api/jobcommon/departmentSearch/get/baseList/",
  job_cmn_department_accoDep_List:
    "tdsjob/api/jobcommon/departmentSearch/get/accountingDepartment",
  job_cmn_department_dep_List:
    "tdsjob/api/jobcommon/departmentSearch/get/departmentList/",
  job_cmn_department_deptView_List:
    "tdsjob/api/jobcommon/departmentSearch/get/deptViewList",
  job_cmn_department_inProgress_List:
    "tdsjob/api/jobcommon/departmentSearch/get/inProgressDept",
  job_cmn_employee_list: "tdsjob/api/jobcommon/employeeSearch/get/employeeList",
  job_cmn_tiss_employee_List:
    "tdsjob/api/jobcommon/tissEmployeeSearch/get/tissEmployeeList/",
  job_cmn_estimateLedger_List:
    "tdsjob/api/jobcommon/estimateLedgerSearch/get/estList/",
  job_cmn_estNum_List: "tdsjob/api/jobcommon/estNumNumbering/get/",
  job_cmn_orderer_List: "tdsjob/api/jobcommon/ordererSearch/get/ordererList/",
  job_cmn_plannedEngineer_engineer_List:
    "tdsjob/api/jobcommon/plannedEngineerSearch/get/engineerList/",
  job_cmn_plannedEngineer_planEng_List:
    "tdsjob/api/jobcommon/plannedEngineerSearch/get/plannedEngineerList/",
  job_cmn_salesDiv_list:
    "tdsjob/api/jobcommon/salesDivSearch/get/salesDivList/",
  job_cmn_salesDiv_get: "tdsjob/api/jobcommon/salesDivSearch/get/salesDiv/",
  job_cmn_buildingPermit_get: "tdsjob/api/jobcommon/buildingPermit/get/",
  job_cmn_toshibaDep_company_List:
    "tdsjob/api/jobcommon/toshibaDepartment/get/toshibaCompanyList/",
  job_cmn_toshibaDep_dep_List:
    "tdsjob/api/jobcommon/toshibaDepartment/get/toshibaDepList/",
  job_cmn_toshibaDep_dep_get:
    "tdsjob/api/jobcommon/toshibaDepartment/get/toshibaDep/",
  job_cmn_toshibaDep_dep_post:
    "tdsjob/api/jobcommon/toshibaDepartment/post/toshibaDep/",
  job_cmn_toshibaDep_dep_put:
    "tdsjob/api/jobcommon/toshibaDepartment/put/toshibaDep/",
  job_cmn_toshibaDep_dep_delete:
    "tdsjob/api/jobcommon/toshibaDepartment/delete/toshibaDep/",
  job_cmn_toshibaOrderNum_List:
    "tdsjob/api/jobcommon/toshibaOrderNumber/get/toshibaInfoList/",
  job_cmn_autoDate_get: "tdsjob/api/jobcommon/autoDate/get",
  job_cmn_siteInfo_get: "tdsjob/api/inquiry/installsite/get/",
  job_cmn_commPrice_get: "tdsjob/api/jobcommon/master/commprice/get",

  job_cmn_mst: "tdsjob/api/jobcommon/master",
  job_cmn_mst_agentdiv_list: "tdsjob/api/jobcommon/master/agentdiv/list",
  job_cmn_mst_bizcatgdiv_list: "tdsjob/api/jobcommon/master/bizcatgdiv/extlist", // 業種区分リスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/biz-catg-div-mst-rest-controller
  job_cmn_mst_budiv_list: "tdsjob/api/jobcommon/master/budiv/extlist", // BU区分リスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/bu-div-mst-rest-controller
  job_cmn_mst_commrank_list: "tdsjob/api/jobcommon/master/commrank/list", // 口銭ランクマスタ
  job_cmn_mst_divseries_list: "tdsjob/api/jobcommon/master/divseries/list", // 事業部系列マスタ
  job_cmn_mst_fdcd_list: "tdsjob/api/jobcommon/master/fdcd/list/all", // FDコードリスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/fd-cd-mst-rest-controller
  job_cmn_mst_generalpurpose_list:
    "tdsjob/api/jobcommon/master/generalpurpose/list", // 汎用マスタ
  job_cmn_mst_itemdiv_list: "tdsjob/api/jobcommon/master/itemdiv/list", // 手配区分リスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/item-div-mst-rest-controller
  job_cmn_mst_productcat_list: "tdsjob/api/jobcommon/master/productcat/list", // 製番カテゴリリスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/product-cat-rest-controller
  job_cmn_mst_rodiv_list: "tdsjob/api/jobcommon/master/rodiv/list", // 受注区分リスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/ro-div-mst-rest-controller
  job_cmn_mst_sbu_list: "tdsjob/api/jobcommon/master/sbu/extlist", // SBUリスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/sbu-mst-rest-controller
  job_cmn_mst_spcdiv_list: "tdsjob/api/jobcommon/master/spcdiv/list", // SPC区分リスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/spc-div-mst-rest-controller
  job_cmn_mst_tax_list: "tdsjob/api/jobcommon/master/tax/list", // 税区分リスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/tax-division-rest-controller
  job_cmn_mst_tax_list_progress:
    "tdsjob/api/jobcommon/master/tax/list/progress", // 税区分リスト取得(経過措置物件対応)
  job_cmn_mst_tindst_list: "tdsjob/api/jobcommon/master/tindst/list", // 東芝産別マスタ
  job_cmn_mst_tindst_get: "tdsjob/api/jobcommon/master/tindst/getList/",
  job_cmn_mst_unit_list: "tdsjob/api/jobcommon/master/unit/list", // 単位リスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/unit-mst-rest-controller
  job_cmn_mst_yukirosaiInsDiv_list:
    "tdsjob/api/jobcommon/master/yukirosaiInsureDiv/list", // 有期労災附保区分リスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/yukirosai-insure-div-mst-rest-controller
  job_cmn_mst_depart_list: "tdsjob/api/jobcommon/master/depart/list/all", // 部課リスト取得（全部課） 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/depart-mst-rest-controller/listUsingGET_6
  job_cmn_mst_apprdepart_list:
    "tdsjob/api/jobcommon/master/depart/list/apprDepart", // 計上部課リスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/depart-mst-rest-controller/listApprDepartUsingGET
  job_cmn_mst_roledepart_list:
    "tdsjob/api/jobcommon/master/depart/list/roleDepart", // 権限部課リスト（選択項目用）取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/depart-mst-rest-controller/listRoleDepartUsingGET
  job_cmn_mst_roleDepart_list_tdsapl:
    "tdsjob/api/jobcommon/master/roleDepart/list/tdsapl", // 権限部課リスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/%E6%A8%A9%E9%99%90%E9%83%A8%E8%AA%B2%E3%83%9E%E3%82%B9%E3%82%BF%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC
  job_cmn_mst_reqkishu_list: "tdsjob/api/jobcommon/master/reqKishu/list", // 依頼用機種リスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/%E4%BE%9D%E9%A0%BC%E7%94%A8%E6%A9%9F%E7%A8%AE%E3%83%9E%E3%82%B9%E3%82%BF%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9/listUsingGET_15
  job_cmn_mst_heapcheckdiv_list:
    "tdsjob/api/jobcommon/master/heapCheckDiv/list", // 山積点検区分リスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/%E5%B1%B1%E7%A9%8D%E7%82%B9%E6%A4%9C%E5%8C%BA%E5%88%86%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9/listUsingGET_12
  job_cmn_mst_statusmng_list: "tdsjob/api/jobcommon/master/statusmng/list",
  job_cmn_mst_bldIndustryLicTyp_list:
    "tdsjob/api/jobcommon/master/bldIndustryLicTyp/list", // 建設業許可種類リスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/%E5%BB%BA%E8%A8%AD%E6%A5%AD%E8%A8%B1%E5%8F%AF%E7%A8%AE%E9%A1%9E%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC/listUsingGET_2
  job_cmn_mst_facilityClass_list:
    "tdsjob/api/jobcommon/master/facilityClass/allList",
  job_cmn_mst_engineerMngDepart_list:
    "tdsjob/api/jobcommon/master/depart/list/engineerMngDepart/all", // 技術者管理部課マスタリスト取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/%E9%83%A8%E8%AA%B2%E3%83%9E%E3%82%B9%E3%82%BF%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC/engineerMngDepartListUsingGET
  job_cmn_mst_EstRepresent_get:
    "tdsjob/api/jobcommon/master/depart/get/EstRepresent", // 見積代表者情報取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/%E9%83%A8%E8%AA%B2%E3%83%9E%E3%82%B9%E3%82%BF%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC/engineerMngDepartListUsingGET
  job_cmn_mst_tdsToricoEdi_get: "tdsjob/api/jobcommon/master/tdsToricoEdi/get", // TDS_TORICO_EDI 取得 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/TDS_TORICO_EDI%E6%A4%9C%E7%B4%A2%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC/getUsingGET_39
  job_cmn_mst_tdsToricoEdi_getBySeibanryakuban:
    "tdsjob/api/jobcommon/master/tdsToricoEdi/getBySeibanryakuban", // TDS_TORICO_EDI 取得(製番略番) 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/TDS_TORICO_EDI%E6%A4%9C%E7%B4%A2%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC/getBySeibanryakubanUsingGET
  job_cmn_mst_tdsToricoEdi_getBySeibanryakuban_toricoCd:
    "tdsjob/api/jobcommon/master/tdsToricoEdi/getBySeibanryakubanAndToricoCd", // TDS_TORICO_EDI 取得(製番略番, 発注先CD) 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/TDS_TORICO_EDI%E6%A4%9C%E7%B4%A2%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC/getBySeibanryakubanUsingGET
  job_cmn_bizContent_list:
    "tdsjob/api/jobcommon/bizContentSearch/get/bizContentList", // 業務内容検索処理 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/%E6%A5%AD%E5%8B%99%E5%86%85%E5%AE%B9%E6%A4%9C%E7%B4%A2%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC/getUsingPOST_1
  job_cmn_torihikisaki_list:
    "tdsjob/api/jobcommon/torihikisakiSearch/get/torihikisakiList", // 取引先検索処理 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/%E5%8F%96%E5%BC%95%E5%85%88%E6%A4%9C%E7%B4%A2%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC/getUsingPOST_7
  job_cmn_engineer_list: "tdsjob/api/jobcommon/engineerSearch/get/engineerList", // 作業員検索処理 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/%E4%BD%9C%E6%A5%AD%E5%93%A1%E6%A4%9C%E7%B4%A2%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC/getUsingPOST_4
  job_cmn_engineer_list_save: "tdsjob/api/jobcommon/engineerSearch/save", // 作業員登録処理 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/%E4%BD%9C%E6%A5%AD%E5%93%A1%E6%A4%9C%E7%B4%A2%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC/saveUsingPOST
  job_cmn_tmpEngineer_get:
    "tdsjob/api/jobcommon/tmpEngineerRegist/get/tmpEngineer", // 仮技術者登録 検索処理 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/%E4%BB%AE%E6%8A%80%E8%A1%93%E8%80%85%E7%99%BB%E9%8C%B2%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC/getUsingGET_35
  job_cmn_tmpEngineer_save: "tdsjob/api/jobcommon/tmpEngineerRegist/save", // 仮技術者登録 登録更新処理 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/%E4%BB%AE%E6%8A%80%E8%A1%93%E8%80%85%E7%99%BB%E9%8C%B2%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC/saveUsingPOST_1
  job_cmn_tmpEngineer_delete: "tdsjob/api/jobcommon/tmpEngineerRegist/delete", // 仮技術者登録 削除 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/%E4%BB%AE%E6%8A%80%E8%A1%93%E8%80%85%E7%99%BB%E9%8C%B2%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9%E3%83%BC/deleteUsingGET
  job_cmn_basHeadSrSalesHead_list:
    "tdsjob/api/jobcommon/basHeadSrSalesHead/get/list", // 基本ヘッダー_SR売上HEADリスト取得
  job_cmn_mst_prcJobTree_get: "tdsjob/api/jobcommon/master/prcJobTree/get", //prcJobTree検索
  job_cmn_mst_prcJobTree_getBigTree:
    "tdsjob/api/jobcommon/master/prcJobTree/getBigTree", //prcJobTree(最大値)検索
  job_cmn_mst_mngSectionRelated_list:
    "tdsjob/api/jobcommon/master/mngSectionRelated/getList", // 管理部課対応表検索処理 参照＞＞http://localhost:8087/tdsapl/tdsjob/api/swagger-ui.html#/%E7%AE%A1%E7%90%86%E9%83%A8%E8%AA%B2%E5%AF%BE%E5%BF%9C%E8%A1%A8%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%A9/getUsingGET_28

  // /* ドキュメント管理システム */
  // dms_get_authority_map: "/dms/api/getAuthorityMap",

  // dms_docMain_search: "/dms/api/docMain/search",
  // dms_docMain_delete: "/dms/api/docMain/delete",
  // dms_docMain_docFile_delete: "/dms/api/docMain/docFile/delete",

  // dms_docDetail_get: "/dms/api/detail/",
  // dms_docDetail_get_rev: "/dms/api/detailHist/",
  // dms_docDetail_update: "/dms/api/detail/update",
  // dms_docDetail_fileUpload: "/dms/api/detail/fileUpload/",
  // dms_docDetail_delete: "/dms/api/detail/delete",
  // dms_docDetail_deleteFiles: "/dms/api/detail/deleteFiles",
  // dms_docDetail_deleteAllDocFiles: "/dms/api/detail/deleteAllDocFiles",
  // dms_docDetail_get_karte: "/dms/api/detail/karte/",
  // dms_docDetail_update_karte: "/dms/api/detail/update/karte",

  // dms_docAttr_get: "/dms/api/docAttrMst/get/",
  // dms_docAttr_create: "/dms/api/docAttrMst/newRegist",
  // dms_docAttr_update: "/dms/api/docAttrMst/regist",

  // dms_docType_get: "/dms/api/docTypeMst/get/",
  // dms_docType_get_by_job_class: "/dms/api/docTypeMst/getByJobClass/",
  // dms_docType_getAll: "/dms/api/docTypeMst/getAll",
  // dms_docType_parentDocTypeList_get:
  //   "/dms/api/docTypeMst/parentDocTypeList/get",
  // dms_docType_jobClassList_get: "/dms/api/docTypeMst/jobClassList/get",
  // dms_docType_reguList_get: "/dms/api/docTypeMst/reguList/get",
  // dms_docType_create: "/dms/api/docTypeMst/newRegist",
  // dms_docType_update: "/dms/api/docTypeMst/regist",

  // dms_docReference_get: "/dms/api/docReference/reference",
  // dms_docReference_get_karte: "/dms/api/docReference/reference/karte",
  // dms_docReference_getJobDocReference: "/dms/api/docReference/JobDocReference",
  // dms_docReference_getJobDocReference_reuse_find:
  //   "/dms/api/docReference/JobDocReference/reuseFind",
  // dms_docReference_getRevisionJudgeData:
  //   "/dms/api/docReference/getRevisionJudgeData",
  // dms_docReference_getRevisionJudgeDataForEviList:
  //   "/dms/api/docReference/getRevisionJudgeDataForEviList",
  // dms_docReference_getRevJudgeDataForEviListByDocId:
  //   "/dms/api/docReference/getRevJudgeDataForEviListByDocId",
  // dms_docReference_delete: "/dms/api/docReference/delete",
  // dms_docReference_attr_delete: "/dms/api/docReference/docAttry/delete",
  // dms_docReference_doc_copy: "/dms/api/docReference/docCopy",
  // dms_docReference_findByKeyAndValue: "/dms/api/docReference/findByKeyAndValue",
  // dms_docReference_findByKeyAndValueList:
  //   "/dms/api/docReference/findByKeyAndValueList",

  // dms_docDisclosure_get: "/dms/api/reference/kaiji",
  // dms_docDisclosure_update: "/dms/api/reference/kaiji/update",

  // dms_docThumnail_get: "/dms/api/reference/thumnail",
  // dms_docThumnail_getFile: "/dms/api/file/",
  // dms_docThumnail_download_zip: "/dms/api/downloadZip",

  // dms_docRegist_get: "/dms/api/regist/",
  // dms_docRegist_create: "/dms/api/regist/doRegist",
  // dms_docRegist_delete: "/dms/api/regist/delete",

  // dms_docTrash_get: "/dms/api/docTrash/search/",
  // dms_docTrash_restore: "/dms/api/docTrash/restore",
  // dms_docTrash_delete: "/dms/api/docTrash/delete",
  // dms_docTrash_forceDelete: "/dms/api/docTrash/deleteById",

  // dms_docDragAndDrop_get: "/dms/api/dragAndDrop/",
  // dms_docDragAndDrop_upload_bukken: "/dms/api/dragAndDrop/fileUpload/",
  // dms_docDragAndDrop_upload_productNum:
  //   "/dms/api/dragAndDrop/fileUploadByProductNum/",
  // dms_docDragAndDrop_upload_seiban:
  //   "/dms/api/dragAndDrop/fileUploadBySeibanRyakuban/",
  // dms_docDragAndDrop_upload_chuban:
  //   "/dms/api/dragAndDrop/fileUploadByChubanRyakuban/",
  // dms_docDragAndDrop_upload_inquiryNo:
  //   "/dms/api/dragAndDrop/fileUploadByInquiryNo/",
  // dms_docDragAndDrop_upload_roNo: "/dms/api/dragAndDrop/fileUploadByRoNo/",
  // dms_docDragAndDrop_upload_siteNo:
  //   "/dms/api/dragAndDrop/fileUploadBySetchibasho/",
  // dms_docDragAndDrop_upload_notifyNo:
  //   "/dms/api/dragAndDrop/fileUploadByNotifyNo/",
  // dms_docDragAndDrop_upload_itemAttachKey:
  //   "/dms/api/dragAndDrop/fileUploadBySeibanRyakubanAndItemAttachKey/",
  // dms_docDragAndDrop_upload_delyAttachKey:
  //   "/dms/api/dragAndDrop/fileUploadByDelyAttachKey/",

  // dms_docDragAndDropFolderd_get: "/dms/api/dragAndDropFolderd/",
  // dms_docDragAndDropFolderd_upload_bukken:
  //   "/dms/api/dragAndDropFolderd/fileUpload/",
  // dms_docDragAndDropFolderd_createFolder:
  //   "/dms/api/dragAndDropFolderd/updateTitle/",
};
export default uriList;
