/* 協業型ポータルuri */
import { BASE_URL } from "@/utils/api/urlList";
// export { default as accessLog } from "./accessLog/index";

export default {
  /* 協業会社リスト 取得 */
  get_colbCoList: `${BASE_URL}/common/get/colbCoList`,
  /* 協業ユーザロールリスト 取得 */
  get_colbUserRoleList: `${BASE_URL}/common/get/colbUserRoleList`,
  /* 月リスト 取得 */
  get_monthList: `${BASE_URL}/common/get/monthList`,
  /* アクセス種別リスト 取得 */
  get_accessTypeList: `${BASE_URL}/common/get/accessTypeList`,
  /* 協業ユーザロールリスト 取得 */
  get_subsystemList: `${BASE_URL}/common/get/subsystemList`,
  /* コンテンツメニュー 取得 */
  get_contentsMenu: `${BASE_URL}/contentsMenu/get`,
  /* 利用規約同意確認 取得 */
  get_termsOfUse_agreement: `${BASE_URL}/termsofuse/get`,
  /* 利用規約同意確認 同意 */
  update_termsOfUse_agreement: `${BASE_URL}/termsofuse/agree`,
  /* ファイル閲覧 */
  get_browse_file: `${BASE_URL}/colbDoc/browseFile`,
};
