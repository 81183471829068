const rest = require("tds-colb-cmns/src/utils/api/restCmn");
import colbUri from "@/utils/api/uris/colb/index";

export default {
  getTermsOfUseAgreement(colbUserId) {
    return rest.get(colbUri.get_termsOfUse_agreement + "/" + colbUserId);
  },
  updateTermsOfUseAgreement(form) {
    return rest.post(colbUri.update_termsOfUse_agreement, form);
  },
};
