import MasterDataService from "@/services/masterDataService.js";
const cmnMsg = require("@/utils/message/commonMessage");

export const masterData = {
  namespaced: true,
  state: {
    //代理店区分マスタ
    // agentdivList: [],
    //業種区分マスタ
    // bizcatgdivList: [],
    //BU区分マスタ
    // budivList: [],
    //口銭ランクマスタ
    // commrankList: [],
    //事業部系列マスタ
    // divseriesList: [],
    //FDコードマスタ
    // fdcdList: [],
    //汎用マスタ
    generalpurposeList: [], //USE!!!!!!!!!!!!!!!!
    //手配区分マスタ
    // itemdivList: [],
    //製番カテゴリマスタ
    // productcatList: [],
    //受注区分マスタ
    // rodivList: [],
    //SBUマスタ
    // sbuList: [],
    //SPC区分マスタ
    // spcdivList: [],
    //税区分マスタ
    // taxList: [],
    //税区分マスタ(経過措置物件対応)
    // taxListProgress: [],
    //東芝産別マスタ
    // tindstList: [],
    //単位マスタ
    // unitList: [],
    //有期労災附保区分マスタ
    // yukirosaiInsDivList: [],
    /* 部課マスタ（全部課） */
    mstDepartList: [], //USE!!!!!!!!!!!!!!!!
    /* 計上部課リスト */
    // mstApprdepartList: [],
    /* 権限部課リスト（選択項目用） */
    // mstRoleDepartList: [],
    /* 製番一覧(ツリー) */
    // trnSeibanTree: [],
    /* 依頼用機種リスト */
    // reqkishuList: [],
    /* 山積点検区分リスト */
    // heapCheckDivList: [],
    /* 売上区分リスト */
    // salesDivList: [],
    /* 商流区分リスト */
    // channelsDivList: [],
    /* 従業員リスト */
    employeeList: [], //USE!!!!!!!!!!!!!!!???
    /* ステータスリスト */
    // statusList: [],
    /* 建設業許可種類リスト */
    // bldIndustryLicTyp: [],
    /* 設備分類名リスト */
    // facilityClassList: [],
    /* 技術者管理部課マスタリスト */
    // engineerMngDepartList: [],
    /* 基本ヘッダー_SR売上HEADリスト */
    // basHeadSrSalesHeadList: [],
    /* 権限部課リスト（勘定年月：TDSAPL、ログイン者所属部課絞込み） */
    // roleDepartListTdsapl: [],
    /** 集計ポイントマスタリスト（計上部門リスト） */
    // accoDepList: [],
    /** 部課マスタVIEWリスト*/
    // deptViewList: [],
    /** 指示者区分リスト */
    // indictrDivList: [],
    /** 管理部課拠点リスト */
    mngDepartBaseList: [], //USE!!!!!!!!!!!!!!!???
    /** ログインユーザの管理者権限 */
    mngrRoles: {}, //USE!!!!!!!!!!!!!!!???
  },
  getters: {
    // emptyAgentdivList: (state) => {
    //   return Object.keys(state.agentdivList).length == 0;
    // },
    // emptyBizcatgdivList: (state) => {
    //   return Object.keys(state.bizcatgdivList).length == 0;
    // },
    // emptyBudivList: (state) => {
    //   return Object.keys(state.budivList).length == 0;
    // },
    // emptyCommrankList: (state) => {
    //   return Object.keys(state.commrankList).length == 0;
    // },
    // emptyDivseriesList: (state) => {
    //   return Object.keys(state.divseriesList).length == 0;
    // },
    // emptyFdcdList: (state) => {
    //   return Object.keys(state.fdcdList).length == 0;
    // },
    emptyGeneralpurposeList: (state) => {
      return Object.keys(state.generalpurposeList).length == 0;
    },
    // emptyItemdivList: (state) => {
    //   return Object.keys(state.itemdivList).length == 0;
    // },
    // emptyProductcatList: (state) => {
    //   return Object.keys(state.productcatList).length == 0;
    // },
    // emptyRodivList: (state) => {
    //   return Object.keys(state.rodivList).length == 0;
    // },
    // emptySbuList: (state) => {
    //   return Object.keys(state.sbuList).length == 0;
    // },
    // emptySpcdivList: (state) => {
    //   return Object.keys(state.spcdivList).length == 0;
    // },
    // emptyTaxList: (state) => {
    //   return Object.keys(state.taxList).length == 0;
    // },
    // emptyTaxListProgress: (state) => {
    //   return Object.keys(state.taxListProgress).length == 0;
    // },
    // emptyTindstList: (state) => {
    //   return Object.keys(state.tindstList).length == 0;
    // },
    // emptyUnitList: (state) => {
    //   return Object.keys(state.unitList).length == 0;
    // },
    // emptyYukirosaiInsDivList: (state) => {
    //   return Object.keys(state.yukirosaiInsDivList).length == 0;
    // },
    emptyMstDepartList: (state) => {
      return Object.keys(state.mstDepartList).length == 0;
    },
    // emptyMstApprdepartList: (state) => {
    //   return Object.keys(state.mstApprdepartList).length == 0;
    // },
    // emptyMstRoleDepartList: (state) => {
    //   return Object.keys(state.mstRoleDepartList).length == 0;
    // },
    // emptyReqkishuList: (state) => {
    //   return Object.keys(state.reqkishuList).length == 0;
    // },
    // emptyHeapCheckDivList: (state) => {
    //   return Object.keys(state.heapCheckDivList).length == 0;
    // },
    // emptySalesDivList: (state) => {
    //   return Object.keys(state.salesDivList).length == 0;
    // },
    // emptyChannelsDivList: (state) => {
    //   return Object.keys(state.channelsDivList).length == 0;
    // },
    emptyEmployeeList: (state) => {
      return Object.keys(state.employeeList).length == 0;
    },
    // emptyStatusList: (state) => {
    //   return Object.keys(state.statusList).length == 0;
    // },
    // emptyBldIndustryLicTyp: (state) => {
    //   return Object.keys(state.bldIndustryLicTyp).length == 0;
    // },
    // emptyFacilityClassList: (state) => {
    //   return Object.keys(state.facilityClassList).length == 0;
    // },
    // emptyEngineerMngDepartList: (state) => {
    //   return Object.keys(state.engineerMngDepartList).length == 0;
    // },
    // emptyBasHeadSrSalesHeadList: (state) => {
    //   return Object.keys(state.basHeadSrSalesHeadList).length == 0;
    // },
    // emptyRoleDepartListTdsapl: (state) => {
    //   return Object.keys(state.roleDepartListTdsapl).length == 0;
    // },
    // emptyAccoDepList: (state) => {
    //   return Object.keys(state.accoDepList).length == 0;
    // },
    // emptyIndictrDivList: (state) => {
    //   return Object.keys(state.indictrDivList).length == 0;
    // },
    emptyMngDepartBaseList: (state) => {
      return Object.keys(state.mngDepartBaseList).length == 0;
    },
    emptyMngrRoles: (state) => {
      return Object.keys(state.mngrRoles).length == 0;
    },
  },
  mutations: {
    // 単一Entityの格納に使用
    // SET_AGENT_DIV_LIST(state, value) {
    //   state.agentdivList = value;
    // },
    // SET_BIZ_CATG_DIV_LIST(state, value) {
    //   state.bizcatgdivList = value;
    // },
    // SET_BU_DIV_LIST(state, value) {
    //   state.budivList = value;
    // },
    // SET_COMM_RANK_LIST(state, value) {
    //   state.commrankList = value;
    // },
    // SET_DIV_SERIES_LIST(state, value) {
    //   state.divseriesList = value;
    // },
    // SET_FDCD_LIST(state, value) {
    //   state.fdcdList = value;
    // },
    SET_GENERAL_PURPOSE_LIST(state, value) {
      state.generalpurposeList = value;
    },
    // SET_ITEM_DIV_LIST(state, value) {
    //   state.itemdivList = value;
    // },
    // SET_PRODUCT_CAT_LIST(state, value) {
    //   state.productcatList = value;
    // },
    // SET_RO_DIV_LIST(state, value) {
    //   state.rodivList = value;
    // },
    // SET_SBU_LIST(state, value) {
    //   state.sbuList = value;
    // },
    // SET_SPC_DIV_LIST(state, value) {
    //   state.spcdivList = value;
    // },
    // SET_TAX_LIST(state, value) {
    //   state.taxList = value;
    // },
    // SET_TAX_LIST_PROGRESS(state, value) {
    //   state.taxListProgress = value;
    // },
    // SET_TINDST_LIST(state, value) {
    //   state.tindstList = value;
    // },
    // SET_UNIT_LIST(state, value) {
    //   state.unitList = value;
    // },
    // SET_YUKIROSAI_INS_DIV_LIST(state, value) {
    //   state.yukirosaiInsDivList = value;
    // },
    SET_MST_DEPART_LIST(state, value) {
      state.mstDepartList = value;
    },
    // SET_MST_APPRDEPART_LIST(state, value) {
    //   state.mstApprdepartList = value;
    // },
    // SET_MST_ROLEDEPART_LIST(state, value) {
    //   state.mstRoleDepartList = value;
    // },
    // SET_TRN_SEIBAN_TREE(state, value) {
    //   state.trnSeibanTree = value;
    // },
    // SET_REQ_KISHU_LIST(state, value) {
    //   state.reqkishuList = value;
    // },
    // SET_HEAP_CHECK_DIV_LIST(state, value) {
    //   state.heapCheckDivList = value;
    // },
    // SET_SALES_DIV_LIST(state, value) {
    //   state.salesDivList = value;
    // },
    // SET_CHANNELS_DIV_LIST(state, value) {
    //   state.channelsDivList = value;
    // },
    SET_EMPLOYEE_LIST(state, value) {
      state.employeeList = value;
    },
    // SET_STATUS_LIST(state, value) {
    //   state.statusList = value;
    // },
    // SET_BLD_INDUSTRY_LIC_TYPE_LIST(state, value) {
    //   state.bldIndustryLicTyp = value;
    // },
    // SET_FACILITY_CLASS_LIST(state, value) {
    //   state.facilityClassList = value;
    // },
    // SET_ENGINEER_MNG_DEPART_LIST(state, value) {
    //   state.engineerMngDepartList = value;
    // },
    // SET_BAS_HEAD_SR_SALES_HEAD_LIST(state, value) {
    //   state.basHeadSrSalesHeadList = value;
    // },
    // SET_ROLE_DEPART_LIST_TDSAPL(state, value) {
    //   state.roleDepartListTdsapl = value;
    // },
    // SET_ACCO_DEP_LIST(state, value) {
    //   state.accoDepList = value;
    // },
    // SET_DEPT_VIEW_LIST(state, value) {
    //   state.deptViewList = value;
    // },
    // SET_INDICTR_DIV_LIST(state, value) {
    //   state.indictrDivList = value;
    // },
    SET_MNG_DEPART_BASE_LIST(state, value) {
      state.mngDepartBaseList = value;
    },
    SET_MNGR_ROLES(state, value) {
      state.mngrRoles = value;
    },
  },
  actions: {
    /* 代理店区分リスト 取得 */
    // async fetchAgentdivList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyAgentdivList",
    //     execMethod: "getAgentdivList",
    //     setterMethod: "SET_AGENT_DIV_LIST",
    //     errObj: {
    //       0: "代理店区分リスト",
    //       1: "fetchAgentdivList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 業種区分リスト 取得 */
    // async fetchBizcatgdivList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyBizcatgdivList",
    //     execMethod: "getBizcatgdivList",
    //     setterMethod: "SET_BIZ_CATG_DIV_LIST",
    //     errObj: {
    //       0: "業種区分リスト",
    //       1: "fetchBizcatgdivList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* BU区分リスト 取得 */
    // async fetchBudivList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyBudivList",
    //     execMethod: "getBudivList",
    //     setterMethod: "SET_BU_DIV_LIST",
    //     errObj: { 0: "BU区分リスト", 1: "fetchBudivList", 2: "情報取得処理" },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 口銭ランクリスト 取得 */
    // async fetchCommrankList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyCommrankList",
    //     execMethod: "getCommrankList",
    //     setterMethod: "SET_COMM_RANK_LIST",
    //     errObj: {
    //       0: "口銭ランクリスト",
    //       1: "fetchCommrankList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 事業部系列リスト 取得 */
    // async fetchDivseriesList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyDivseriesList",
    //     execMethod: "getDivseriesList",
    //     setterMethod: "SET_DIV_SERIES_LIST",
    //     errObj: {
    //       0: "事業部系列リスト",
    //       1: "fetchDivseriesList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* FDコードリスト 取得 */
    // async fetchFdcdList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyFdcdList",
    //     execMethod: "getFdcdList",
    //     setterMethod: "SET_FDCD_LIST",
    //     errObj: { 0: "FDコードリスト", 1: "fetchFdcdList", 2: "情報取得処理" },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 汎用リスト 取得 */
    async fetchGeneralpurposeList(context, payload) {
      const obj = {
        payload: payload,
        emptyCheckMethod: "emptyGeneralpurposeList",
        execMethod: "getGeneralpurposeList",
        setterMethod: "SET_GENERAL_PURPOSE_LIST",
        errObj: {
          0: "汎用リスト",
          1: "fetchGeneralpurposeList",
          2: "情報取得処理",
        },
      };
      return context.dispatch("commonCallService", obj);
    },

    /* 手配区分リスト 取得 */
    // async fetchItemdivList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyItemdivList",
    //     execMethod: "getItemdivList",
    //     setterMethod: "SET_ITEM_DIV_LIST",
    //     errObj: {
    //       0: "手配区分リスト",
    //       1: "fetchItemdivList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 製番カテゴリリスト 取得 */
    // async fetchProductcatList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyProductcatList",
    //     execMethod: "getProductcatList",
    //     setterMethod: "SET_PRODUCT_CAT_LIST",
    //     errObj: {
    //       0: "製番カテゴリリスト",
    //       1: "fetchProductcatList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 受注区分リスト 取得 */
    // async fetchRodivList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyRodivList",
    //     execMethod: "getRodivList",
    //     setterMethod: "SET_RO_DIV_LIST",
    //     errObj: { 0: "受注区分リスト", 1: "fetchRodivList", 2: "情報取得処理" },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* SBUリスト 取得 */
    // async fetchSbuList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptySbuList",
    //     execMethod: "getSbuList",
    //     setterMethod: "SET_SBU_LIST",
    //     errObj: { 0: "SBUリスト", 1: "fetchSbuList", 2: "情報取得処理" },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* SPC区分リスト 取得 */
    // async fetchSpcdivList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptySpcdivList",
    //     execMethod: "getSpcdivList",
    //     setterMethod: "SET_SPC_DIV_LIST",
    //     errObj: { 0: "SPC区分リスト", 1: "fetchSpcdivList", 2: "情報取得処理" },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 税区分リスト 取得 */
    // async fetchTaxList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyTaxList",
    //     execMethod: "getTaxList",
    //     setterMethod: "SET_TAX_LIST",
    //     errObj: { 0: "税区分リスト", 1: "fetchTaxList", 2: "情報取得処理" },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 税区分リスト 取得 (経過措置物件対応) */
    // async fetchTaxListProgress(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "", //常に再取得
    //     execMethod: "getTaxListProgress",
    //     setterMethod: "SET_TAX_LIST_PROGRESS",
    //     errObj: {
    //       0: "税区分リスト",
    //       1: "fetchTaxListProgress",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 東芝産別リスト 取得 */
    // async fetchTindstList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyTindstList",
    //     execMethod: "getTindstList",
    //     setterMethod: "SET_TINDST_LIST",
    //     errObj: {
    //       0: "東芝産別リスト",
    //       1: "fetchTindstList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 単位リスト 取得 */
    // async fetchUnitList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyUnitList",
    //     execMethod: "getUnitList",
    //     setterMethod: "SET_UNIT_LIST",
    //     errObj: { 0: "単位リスト", 1: "fetchUnitList", 2: "情報取得処理" },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 有期労災附保区分リスト 取得 */
    // async fetchYukirosaiInsDivList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyYukirosaiInsDivList",
    //     execMethod: "getYukirosaiInsDivList",
    //     setterMethod: "SET_YUKIROSAI_INS_DIV_LIST",
    //     errObj: {
    //       0: "有期労災附保区分リスト",
    //       1: "fetchYukirosaiInsDivList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 部課リスト（全部課） 取得 */
    async fetchMstDepartList(context, payload) {
      const obj = {
        payload: payload,
        emptyCheckMethod: "emptyMstDepartList",
        execMethod: "getMstDepartList",
        setterMethod: "SET_MST_DEPART_LIST",
        errObj: {
          0: "部課リスト（全部課）",
          1: "fetchMstDepartList",
          2: "情報取得処理",
        },
      };
      return context.dispatch("commonCallService", obj);
    },

    /* 計上部課リスト 取得 */
    // async fetchMstApprdepartList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: null,
    //     execMethod: "getMstApprdepartList",
    //     setterMethod: "SET_MST_APPRDEPART_LIST",
    //     errObj: {
    //       0: "計上部課リスト",
    //       1: "fetchMstApprdepartList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 権限部課リスト（選択項目用） 取得 */
    // async fetchMstRoleDepartList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: null,
    //     execMethod: "getMstRoleDepartList",
    //     setterMethod: "SET_MST_ROLEDEPART_LIST",
    //     errObj: {
    //       0: "権限部課リスト",
    //       1: "fetchMstRoleDepartList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 製番一覧(ツリー) 取得 */
    // async fetchTrnSeibanTree(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: null,
    //     execMethod: "getTrnSeibanTree",
    //     setterMethod: "SET_TRN_SEIBAN_TREE",
    //     errObj: {
    //       0: "製番一覧(ツリー)",
    //       1: "fetchTrnSeibanTree",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 依頼用機種リスト 取得 */
    // async fetchReqkishuList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyReqkishuList",
    //     execMethod: "getReqkishuList",
    //     setterMethod: "SET_REQ_KISHU_LIST",
    //     errObj: {
    //       0: "依頼用機種リスト",
    //       1: "fetchReqkishuList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 山積点検区分リスト 取得 */
    // async fetchHeapCheckDivList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyHeapCheckDivList",
    //     execMethod: "getHeapCheckDivList",
    //     setterMethod: "SET_HEAP_CHECK_DIV_LIST",
    //     errObj: {
    //       0: "山積点検区分リスト",
    //       1: "fetchHeapCheckDivList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 売上区分リスト 取得 */
    // async fetchSalesDivList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptySalesDivList",
    //     execMethod: "getSalesDivList",
    //     setterMethod: "SET_SALES_DIV_LIST",
    //     errObj: {
    //       0: "売上区分リスト",
    //       1: "fetchSalesDivList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 商流区分リスト 取得 */
    // async fetchChannelsDivList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyChannelsDivList",
    //     execMethod: "getChannelsDivList",
    //     setterMethod: "SET_CHANNELS_DIV_LIST",
    //     errObj: {
    //       0: "商流区分リスト",
    //       1: "fetchChannelsDivList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 従業員リスト 取得 */
    async fetchEmployeeList(context, payload) {
      const obj = {
        payload: payload,
        emptyCheckMethod: "emptyEmployeeList",
        execMethod: "getEmployeeList",
        setterMethod: "SET_EMPLOYEE_LIST",
        errObj: {
          0: "従業員リスト",
          1: "fetchEmployeeList",
          2: "情報取得処理",
        },
      };
      return context.dispatch("commonCallService", obj);
    },

    /* ステータスリスト 取得 */
    // async fetchStatusList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyStatusList",
    //     execMethod: "getStatusList",
    //     setterMethod: "SET_STATUS_LIST",
    //     errObj: {
    //       0: "ステータスリスト",
    //       1: "fetchStatusList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 建設業許可種類リスト */
    // async fetchBldIndustryLicTyp(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyBldIndustryLicTyp",
    //     execMethod: "getBldIndustryLicTyp",
    //     setterMethod: "SET_BLD_INDUSTRY_LIC_TYPE_LIST",
    //     errObj: {
    //       0: "建設業許可種類リスト",
    //       1: "fetchBldIndustryLicTyp",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 建設業許可種類リスト */
    // async fetchFacilityClassList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyFacilityClassList",
    //     execMethod: "getFacilityClassList",
    //     setterMethod: "SET_FACILITY_CLASS_LIST",
    //     errObj: {
    //       0: "建設業許可種類リスト",
    //       1: "fetchFacilityClassList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 技術者管理部課マスタリスト */
    // async fetchEngineerMngDepartList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyEngineerMngDepartList",
    //     execMethod: "getEngineerMngDepartList",
    //     setterMethod: "SET_ENGINEER_MNG_DEPART_LIST",
    //     errObj: {
    //       0: "技術者管理部課マスタリスト",
    //       1: "fetchEngineerMngDepartList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },

    /* 基本ヘッダー_SR売上HEADリスト */
    // async fetchBasHeadSrSalesHeadList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "", //常に再取得
    //     execMethod: "getBasHeadSrSalesHeadList",
    //     setterMethod: "SET_BAS_HEAD_SR_SALES_HEAD_LIST",
    //     errObj: {
    //       0: "基本ヘッダー_SR売上HEADリスト",
    //       1: "fetchBasHeadSrSalesHeadList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   const promise = context.dispatch("commonCallService", obj);
    //   return promise;
    // },

    /* 受渡条件情報 */
    // async fetchDelvCond(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "", //常に再取得
    //     execMethod: "getDelvCond",
    //     setterMethod: "",
    //     errObj: { 0: "受渡条件情報", 1: "fetchDelvCond", 2: "情報取得処理" },
    //   };
    //   const promise = context.dispatch("commonCallService", obj);
    //   return promise;
    // },

    /* 設置場所情報 */
    // async fetchSiteInfo(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "", //常に再取得
    //     execMethod: "getSiteInfo",
    //     setterMethod: "",
    //     errObj: { 0: "設置場所情報", 1: "fetchSiteInfo", 2: "情報取得処理" },
    //   };
    //   const promise = context.dispatch("commonCallService", obj);
    //   return promise;
    // },

    /* 権限部課リスト（勘定年月：TDSAPL、部課指定） */
    // async fetchRoleDepartListTdsapl(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "", //常に再取得
    //     execMethod: "getRoleDepartListByTdsapl",
    //     setterMethod: "SET_ROLE_DEPART_LIST_TDSAPL",
    //     errObj: {
    //       0: "権限部課リスト（勘定年月：TDSAPL）",
    //       1: "fetchRoleDepartListTdsapl",
    //       2: "情報取得処理",
    //     },
    //   };
    //   const promise = context.dispatch("commonCallService", obj);
    //   return promise;
    // },

    /* 部課マスタVIEWリストを取得 */
    // async fetchDeptViewList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "", //常に再取得
    //     execMethod: "getDeptViewList",
    //     setterMethod: "SET_DEPT_VIEW_LIST",
    //     errObj: {
    //       0: "部課マスタVIEWリスト",
    //       1: "fetchDeptViewList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   const promise = context.dispatch("commonCallService", obj);
    //   return promise;
    // },

    /* 集計ポイントマスタリスト（計上部門リスト）を取得 */
    // async fetchAccoDepList(context, payload) {
    //   const obj = {
    //     payload: payload,
    //     emptyCheckMethod: "emptyAccoDepList",
    //     execMethod: "getAccoDepList",
    //     setterMethod: "SET_ACCO_DEP_LIST",
    //     errObj: {
    //       0: "権限部課リスト（勘定年月：TDSAPL）",
    //       1: "fetchAccoDepList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   const promise = context.dispatch("commonCallService", obj);
    //   return promise;
    // },

    /** 指示者区分リストを取得 */
    // async fetchIndictrDivList(context) {
    //   const obj = {
    //     payload: null,
    //     emptyCheckMethod: "emptyIndictrDivList",
    //     execMethod: "getIndictrDivList",
    //     setterMethod: "SET_INDICTR_DIV_LIST",
    //     errObj: {
    //       0: "指示者区分リスト",
    //       1: "fetchIndictrDivList",
    //       2: "情報取得処理",
    //     },
    //   };
    //   return context.dispatch("commonCallService", obj);
    // },
    fetchMngDepartBaseList(context) {
      const obj = {
        payload: null,
        emptyCheckMethod: "emptyMngDepartBaseList",
        execMethod: "getMngDepartBaseList",
        setterMethod: "SET_MNG_DEPART_BASE_LIST",
        errorObj: {
          0: "管理部課拠点リスト",
          1: "fetchMngDepartBaseList",
          2: "情報取得処理",
        },
      };
      return context.dispatch("commonCallService", obj);
    },
    async fetchMngrRoles(context) {
      const obj = {
        payload: null,
        execMethod: "getMngrRoles",
        setterMethod: "SET_MNGR_ROLES",
        errorObj: {
          0: "ユーザ管理者権限情報",
          1: "fetchMngrRoles",
          2: "情報取得処理",
        },
      };
      return context.dispatch("commonCallService", obj);
    },

    /* Entity or Dto 取得共通メソッド */
    async commonCallService(context, obj) {
      return new Promise((resolve, reject) => {
        if (
          obj.emptyCheckMethod !== undefined &&
          obj.emptyCheckMethod !== null &&
          obj.emptyCheckMethod !== "" &&
          !context.getters[obj.emptyCheckMethod]
        ) {
          resolve();
          return;
        }
        MasterDataService[obj.execMethod](obj.payload)
          .then((res) => {
            if (res.resultStatus) {
              // SET処理が不要な場合はsetterMethodを記述しないこと
              if (
                obj.setterMethod !== undefined &&
                obj.setterMethod !== null &&
                obj.setterMethod !== ""
              ) {
                context.commit(obj.setterMethod, res.resultData);
              }
              resolve({ response: res });
            } else {
              reject({
                response: res,
                msg:
                  cmnMsg.getMessage("PTL_E_0001", obj.errObj) +
                  "resolve = " +
                  JSON.stringify(res, null, 2),
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
