const deepCopy = (val = {}) => JSON.parse(JSON.stringify(val));

export function isEmpty(val) {
  return val === undefined || val === null || val === "";
}

export function isNotEmpty(val) {
  return !isEmpty(val);
}

// オブジェクトの中身が空の場合true
export function isEmptyObject(obj = {}) {
  return !Object.keys(obj).length;
}

// オブジェクトのvalueが空の場合true
export function isEmptyModelObject(obj = {}) {
  return Object.values(obj).every(e => isEmpty(e))
}

export default {
  deepCopy,
};
