<!-- 協業型業務ポータルOTPログイン -->
<template>
  <div class="view-compo-div">
    <!-- ヘッダ -->
    <header-bar isLoginMode ref="ref_header" />

    <!-- ボディ -->
    <v-main class="content text-center">
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col>&nbsp;</v-col>
        </v-row>
        <v-row>
          <v-col>送信されたワンタイムパスワードを入力してください。</v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="fw-300">
            <text-field
              id="password"
              password
              v-model="form.password"
              validate-type="startWithZeroNumericHalf"
              max="6"
              placeholder="ワンタイムパスワード"
              required
              @keydown.enter="onEnter"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="info" @click="login">完了</v-btn>
            &nbsp;&nbsp;
            <v-btn color="primary" outlined @click="cancel">キャンセル</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <action-confirm ref="actionConfirm" />
    </v-main>

    <!-- フッタ -->
    <footer-bar ref="ref_footer" />

    <!-- 全画面ローディングエリア -->
    <loading :active.sync="isLoading" :can-cancel="false" is-full-page />
  </div>
</template>

<script>
import HeaderBar from "tds-colb-cmns/src/components/common/HeaderBar";
import FooterBar from "tds-colb-cmns/src/components/common/FooterBar";
import Loading from "vue-loading-overlay";
import TextField from "tds-colb-cmns/src/components/common/form/TextField";

import colbMixin from "tds-colb-cmns/src/mixins/colbMixin";
import UniversalCommon from "tds-colb-cmns/src/mixins/UniversalCommon";

import ActionConfirm from "@/components/common/dialog/ActionConfirm";

import service from "@/services/common/authService";
import termsService from "@/services/common/termsOfUseService";

export default {
  name: "common-otplogin",
  model: {},
  mixins: [colbMixin, UniversalCommon],
  props: {},
  data: () => ({
    isLoading: false,
    form: {
      userid: null,
      password: null,
    },
  }),
  mounted() {
    this.form.userid = this.$route.params.userid;
    this.$nextTick(() => {
      this.$refs.form.resetValidation();
    });
  },
  methods: {
    cancel() {
      this.$refs.actionConfirm
        .confirm({ message: "ワンタイムパスワードをキャンセルしますか？" })
        .then((res) => {
          if (res) {
            this.$router.push({
              name: "login",
            });
          }
        });
    },
    async login() {
      if (!this.$refs.form.validate()) {
        this.validError();
        return;
      }
      this.isLoading = true;
      let userinfo = null;
      await service
        .otpLogin(this.form.userid, this.form.password)
        .then((res) => {
          userinfo = res.resultData;
          if (!this.isExistValue(res) || !this.isExistValue(res.resultData)) {
            return;
          }
        })
        .catch((err) => {
          this.commonFail(err);
        })
        .finally(() => {
          this.isLoading = false;
        });

      if (!this.isExistValue(userinfo)) {
        return;
      }
      await termsService
        .getTermsOfUseAgreement(userinfo.colb_user_id)
        .then((res) => {
          if (res.resultData.useTermsConsentFlg === "0") {
            this.$router.push({
              name: "termsOfUse",
              params: { userid: `${userinfo.colb_user_id}` },
            });
          } else {
            const prevUri = sessionStorage.getItem("prevUrl");
            if (prevUri) {
              sessionStorage.removeItem("prevUrl");
              location.href = prevUri;
            } else {
              this.$router.push({
                name: "colbFrameTop",
              });
            }
          }
        })
        .catch((err) => {
          this.commonFail(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onEnter(e) {
      e.preventDefault();
      this.login();
    },
  },
  computed: {},
  components: {
    HeaderBar,
    FooterBar,
    Loading,
    TextField,
    ActionConfirm,
  },
};
</script>
<style scoped></style>
