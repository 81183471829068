<!-- 協業型業務ポータルログイン -->
<template>
  <div class="view-compo-div">
    <!-- ヘッダ -->
    <toshiba-header isLoginMode ref="ref_header" />

    <!-- ボディ -->
    <v-main class="content text-center">
      <v-toolbar dense flat dark color="primary">
        <div class="main-title">協業型ポータル ログインページ</div>
      </v-toolbar>
      <v-form class="login-form" ref="form" lazy-validation autocomplete="off">
        <v-row>
          <v-col>&nbsp;</v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="fw-100" align-self="center">
            <label for="username">ユーザID</label>
          </v-col>
          <v-col class="fw-300">
            <text-field
              id="username"
              v-model="form.userid"
              :validate-type="userIdValidateType"
              max="50"
              placeholder="ユーザID"
              required
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="fw-100" align-self="center">
            <label for="userpassword">パスワード</label>
          </v-col>
          <v-col class="fw-300">
            <text-field
              id="userpassword"
              password
              v-model="form.password"
              max="20"
              placeholder="パスワード"
              required
              @keydown.enter="onEnter"
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col>
            <v-btn class="ml-3" color="info" @click="login"
              ><v-icon small>fas fa-sign-in-alt</v-icon> &nbsp;ログイン</v-btn
            >
          </v-col>
        </v-row>
        <v-row justify="center" v-if="!isInternal">
          <v-col>
            <h4 class="ml-5">
              パスワードをお忘れの方は
              <a class="forgot-password" :href="password_change"
                ><span>東芝ユーザID管理システム</span></a
              >
              から再設定を行ってください。
            </h4>
          </v-col>
        </v-row>
      </v-form>
      <action-confirm ref="actionConfirm" />
    </v-main>

    <!-- フッタ -->
    <footer-bar ref="ref_footer" />

    <!-- 全画面ローディングエリア -->
    <loading :active.sync="isLoading" :can-cancel="false" is-full-page />
  </div>
</template>

<script>
import ToshibaHeader from "tds-colb-cmns/src/components/common/ToshibaHeader.vue";
import FooterBar from "tds-colb-cmns/src/components/common/FooterBar";
import Loading from "vue-loading-overlay";
import TextField from "tds-colb-cmns/src/components/common/form/TextField";

import colbMixin from "tds-colb-cmns/src/mixins/colbMixin";
import UniversalCommon from "@/mixins/UniversalCommon.vue";
import ActionConfirm from "@/components/common/dialog/ActionConfirm";

import service from "@/services/common/authService";
import termsService from "@/services/common/termsOfUseService";
import { PASSWORD_CHANGE_URL, HELP_URL } from "@/utils/api/urlList";
import { getJwt } from "tds-colb-cmns/src/utils/auth/userAuth";

export default {
  name: "common-login",
  mixins: [colbMixin, UniversalCommon],
  data: () => ({
    isLoading: false,
    password_change: PASSWORD_CHANGE_URL,
    help: HELP_URL,
    form: {
      userid: null,
      password: null,
    },
  }),
  mounted() {
    this.$nextTick(() => {
      this.$refs.form.resetValidation();
    });
  },
  methods: {
    async login() {
      if (!this.$refs.form.validate()) {
        this.validError();
        return;
      }
      this.isLoading = true;
      let jwt = null;
      let userinfo = null;
      await service
        .login(this.form.userid, this.form.password)
        .then((res) => {
          jwt = getJwt();

          if (this.isExistValue(res.resultData)) {
            userinfo = res.resultData;
          }
        })
        .catch((err) => {
          this.commonFail(err);
          this.isLoading = false;
          return;
        });

      if (!this.isExistValue(userinfo)) {
        this.isLoading = false;
        return;
      }

      // jwtあり：OTP認証省略
      await termsService
        .getTermsOfUseAgreement(userinfo.colb_user_id)
        .then((res) => {
          // console.debug("res", res.resultData);
          // console.debug("jwt", jwt);
          if (!this.isExistValue(jwt)) {
            this.$router.push({
              name: "otpLogin",
              params: { userid: `${userinfo.colb_user_id}` },
            });
          } else if (res.resultData.useTermsConsentFlg === "0") {
            sessionStorage.setItem("userinfo", JSON.stringify(userinfo));
            this.$router.push({
              name: "termsOfUse",
              params: { userid: `${userinfo.colb_user_id}` },
            });
          } else {
            sessionStorage.setItem("userinfo", JSON.stringify(userinfo));
            const prevUri = sessionStorage.getItem("prevUrl");
            if (prevUri) {
              sessionStorage.removeItem("prevUrl");
              location.href = prevUri;
            } else {
              this.$router.push({
                name: "colbFrameTop",
              });
            }
          }
        })
        .catch((err) => {
          this.commonFail(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onEnter(e) {
      e.preventDefault();
      this.login();
    },
  },
  computed: {
    userIdValidateType() {
      const buildType = process.env.VUE_APP_URL;
      return buildType == "/tdsapl/internalColb" ? null : "email";
    },
    isInternal() {
      const buildType = process.env.VUE_APP_URL;
      return buildType.includes("internal");
    },
  },
  components: {
    ToshibaHeader,
    FooterBar,
    Loading,
    TextField,
    ActionConfirm,
  },
};
</script>
<style scoped>
.login-form {
  max-width: 1200px;
  margin: 0 auto;
}
.main-title {
  width: 1100px;
  max-width: 1100px;
  margin: 0 auto;
  text-align: left;
}

.forgot-password {
  text-decoration: none;
}

.forgot-password:hover {
  background-image: linear-gradient(180deg, transparent 80%, #0064d2 0);
  background-repeat: no-repeat;
  background-size: 100% 4px;
  background-position: bottom;
}
</style>
