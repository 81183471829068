<!--機能ナビゲーション(メニュー)-->
<template>
  <v-navigation-drawer
    class="func-navi-drawer"
    :value="drawer"
    :mini-variant.sync="funcNaviMini"
    :mini-variant-width="miniVariantWidth"
    permanent
  >
    <v-toolbar flat class="transparent">
      <v-list class="pa-0 mx-auto">
        <v-list-item>
          <v-list-item-avatar v-if="funcNaviMini">
            <v-btn icon @click.stop="changeMini">
              <v-icon large>fas fa-caret-right</v-icon>
            </v-btn>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="navi-title text-center"
              >メニュー</v-list-item-title
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click.stop="changeMini">
              <v-icon large>fas fa-caret-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-toolbar>

    <v-list class="navi-contents pt-0 px-0" dense>
      <v-list-item
        link
        v-for="item in items"
        v-show="!item.invisible"
        :key="item.key"
        @click.stop="changeContents(item.key)"
        :disabled="item.disabled"
        :class="[!funcNaviMini ? 'ma-2 func-opened' : 'func-mini mx-auto']"
      >
        <div>
          <div
            class="tab-line"
            :class="[
              { accent: item.clicked },
              { exclamation: item.bikkuriMark },
            ]"
          ></div>

          <v-icon
            class="func-navi-icon"
            :class="{ 'info--text': item.clicked }"
            >{{ item.icon }}</v-icon
          >

          <v-list-item-title
            v-if="funcNaviMini"
            class="func-title mx-auto"
            :class="{ 'info--text': item.clicked, minititle: funcNaviMini }"
            >{{ item.titleMini }}</v-list-item-title
          >
        </div>

        <v-list-item-action :class="[funcNaviMini ? 'minititle' : '']">
          <v-list-item-title
            v-if="!funcNaviMini"
            :class="{ 'info--text': item.clicked }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ item.title }}</span>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </v-list-item-title>
        </v-list-item-action>

        <v-list-item-action
          v-if="item.bikkuriMark"
          :class="[funcNaviMini ? 'minititle' : '']"
          right
        >
          <v-spacer />
          <v-icon v-if="item.bikkuriMark" class="input-completed" color="red"
            >fas fa-exclamation-circle</v-icon
          >
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    drawer: {
      type: Boolean,
      default: true,
    },
    value: null,
    miniVariantWidth: {
      type: [Number, String],
      default: 80,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    changeMini() {
      this.funcNaviMini = !this.funcNaviMini;
    },
    changeContents(key) {
      this.items.forEach((item) => {
        if (item.key == key) {
          item.clicked = true;
        } else {
          item.clicked = false;
        }
      });
      const nonClicked = Object.values(this.items)
        .filter((item) => item.clicked)
        .every((v) => v === false);
      if (nonClicked) {
        Object.values(this.items).forEach((item) => {
          if (item.default === true) {
            item.clicked = true;
          }
        });
      }
      this.$emit("change", key);
    },
    ...mapActions("funcNavi", ["setFuncNaviMini"]),
  },
  computed: {
    funcNaviMini: {
      set(val) {
        this.setFuncNaviMini(val);
      },
      get() {
        return this.$store.state.funcNavi.mini;
      },
    },
    ...mapState({
      items: (state) => state.funcNavi.items,
      funcNavi: (state) => state.funcNavi,
    }),
  },
  mounted() {
    this.changeContents(this.$route.params.jobFunc);
  },
};
</script>
<style scoped>
.func-navi-drawer {
  font-size: 14px !important;
  min-width: initial;
}

.func-navi-drawer :deep(header) {
  height: 48px !important;
}

.func-navi-drawer .func-navi-icon {
  font-size: 20px !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.func-navi-drawer :deep(.v-toolbar__content) {
  height: 48px !important;
  padding-left: 4px;
}

.func-navi-drawer :deep(.v-list-item) {
  padding-left: 12px;
}

.func-navi-drawer :deep(.v-list-item__icon) {
  margin-top: 0;
}

.func-navi-drawer :deep(.v-list-item__action) {
  margin-left: 0 !important;
}

.navi-contents :deep(.v-list-item__action) {
  margin-top: 0;
  margin-bottom: 16px;
  margin-left: 0 !important;
}

.navi-contents :deep(.v-list-item) {
  padding-bottom: 8px;
}

.func-navi-drawer .func-mini > div {
  width: 100%;
  text-align: center;
}

.mini-width {
  width: 80px;
}
.open-width {
  width: 200px;
}
.minititle {
  font-size: 11px !important;
  position: absolute;
  /* top: 37px; */
  height: 15px;
}

.minititle > div {
  text-align: center;
}

.minititle .input-completed {
  position: absolute;
  padding-right: 3px;
  font-size: 14px !important;
  top: -17px;
}

.minititle.func-title {
  font-weight: bold !important;
}

.func-navi-drawer :deep(.mx-auto),
.func-navi-drawer :deep(.ma-2) {
  position: relative;
}

.tab-line {
  position: absolute;
  min-width: 3px;
  height: 40px;
}

.func-mini .tab-line {
  top: 10px;
  left: 5px;
}

.func-opened .tab-line {
  top: 8px;
  left: 0;
}

.func-opened .tab-line.exclamation {
  top: 12px;
  left: 0;
}

.func-opened > div {
  margin-bottom: 16px;
  margin-right: 8;
}

.navi-title {
  width: 180px;
}
</style>
