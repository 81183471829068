/** 協業型ポータル */
import ColbService from "@/services/colb/colbService.js";
const cmnMsg = require("@/utils/message/commonMessage");

export const colb = {
  namespaced: true,
  state: {
    // 協業会社リスト
    colbCoList: [],
    // 協業ユーザロールリスト
    colbUserRoleList: [],
    // 月リスト
    monthList: [],
    // アクセス種別リスト
    accessTypeList: [],
    // サブシステムリスト
    subsystemList: [],
  },
  getters: {
    emptyColbCoList: (state) => {
      return Object.keys(state.colbCoList).length == 0;
    },
    emptyColbUserRoleList: (state) => {
      return Object.keys(state.colbUserRoleList).length == 0;
    },
    emptyMonthList: (state) => {
      return Object.keys(state.monthList).length == 0;
    },
    emptyAccessTypeList: (state) => {
      return Object.keys(state.accessTypeList).length == 0;
    },
    emptySubsystemList: (state) => {
      return Object.keys(state.subsystemList).length == 0;
    },
  },
  mutations: {
    SET_COLB_CO_LIST(state, value) {
      state.colbCoList = value;
    },
    SET_COLB_USER_ROLE_LIST(state, value) {
      state.colbUserRoleList = value;
    },
    SET_MONTH_LIST(state, value) {
      state.monthList = value;
    },
    SET_ACCESS_TYPE_LIST(state, value) {
      state.accessTypeList = value;
    },
    SET_SUBSYSTEM_LIST(state, value) {
      state.subsystemList = value;
    },
  },
  actions: {
    setDataAll(context, payload) {
      /* 協業型ポータルのマスタデータ全取得 */
      return new Promise((resolve) => {
        console.debug("store/colb.js setDataAllを呼び出しました。:", payload);
        context.dispatch("fetchColbCoList");
        context.dispatch("fetchColbUserRoleList");
        context.dispatch("fetchMonthList");
        context.dispatch("fetchAccessTypeList");
        context.dispatch("fetchSubsystemList");
        resolve();
      });
    },
    /* 協業会社リスト 取得 */
    async fetchColbCoList(context, payload) {
      const obj = {
        payload: payload,
        emptyCheckMethod: "emptyColbCoList",
        execMethod: "getColbCoList",
        setterMethod: "SET_COLB_CO_LIST",
        errObj: {
          0: "協業会社リスト",
          1: "fetchColbCoList",
          2: "情報取得処理",
        },
      };
      return context.dispatch("colbCallService", obj);
    },
    /* 協業ユーザロールリスト 取得 */
    async fetchColbUserRoleList(context, payload) {
      const obj = {
        payload: payload,
        emptyCheckMethod: "emptyColbCoList",
        execMethod: "getColbUserRoleList",
        setterMethod: "SET_COLB_USER_ROLE_LIST",
        errObj: {
          0: "協業ユーザロールリスト",
          1: "fetchColbUserRoleList",
          2: "情報取得処理",
        },
      };
      return context.dispatch("colbCallService", obj);
    },
    /* 月リスト 取得 */
    async fetchMonthList(context, payload) {
      const obj = {
        payload: payload,
        emptyCheckMethod: "emptyMonthList",
        execMethod: "getMonthList",
        setterMethod: "SET_MONTH_LIST",
        errObj: {
          0: "月リスト",
          1: "fetchMonthList",
          2: "情報取得処理",
        },
      };
      return context.dispatch("colbCallService", obj);
    },
    /* アクセス種別リスト 取得 */
    async fetchAccessTypeList(context, payload) {
      const obj = {
        payload: payload,
        emptyCheckMethod: "emptyAccessTypeList",
        execMethod: "getAccessTypeList",
        setterMethod: "SET_ACCESS_TYPE_LIST",
        errObj: {
          0: "アクセス種別リスト",
          1: "fetchAccessTypeList",
          2: "情報取得処理",
        },
      };
      return context.dispatch("colbCallService", obj);
    },
    /* サブシステムリスト 取得 */
    async fetchSubsystemList(context, payload) {
      const obj = {
        payload: payload,
        emptyCheckMethod: "emptySubsystemList",
        execMethod: "getSubsystemList",
        setterMethod: "SET_SUBSYSTEM_LIST",
        errObj: {
          0: "サブシステムリスト",
          1: "fetchSubsystemList",
          2: "情報取得処理",
        },
      };
      return context.dispatch("colbCallService", obj);
    },

    /* Entity or Dto 取得共通メソッド */
    colbCallService(context, obj) {
      return new Promise((resolve, reject) => {
        if (
          obj.emptyCheckMethod !== undefined &&
          obj.emptyCheckMethod !== null &&
          obj.emptyCheckMethod !== "" &&
          !context.getters[obj.emptyCheckMethod]
        ) {
          resolve();
          return;
        }
        ColbService[obj.execMethod](obj.payload)
          .then((res) => {
            if (res.resultStatus) {
              // SET処理が不要な場合はsetterMethodを記述しないこと
              if (
                obj.setterMethod !== undefined &&
                obj.setterMethod !== null &&
                obj.setterMethod !== ""
              ) {
                context.commit(obj.setterMethod, res.resultData);
              }
              resolve({ response: res });
            } else {
              reject({
                response: res,
                msg:
                  cmnMsg.getMessage("PTL_E_0001", obj.errObj) +
                  "resolve = " +
                  JSON.stringify(res, null, 2),
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
