import colbUri from "@/utils/api/uris/colb/index";
import * as rest from "tds-colb-cmns/src/utils/api/restCmn";
// import models from "@/models/mbo/general/generalMenuModels";

const {
  get_colbCoList,
  get_colbUserRoleList,
  get_monthList,
  get_accessTypeList,
  get_subsystemList,
  get_contentsMenu,
} = colbUri;

export default {
  /* 協業会社リスト 取得 */
  getColbCoList() {
    return rest.get(get_colbCoList);
  },
  /* 協業ユーザロールリスト 取得 */
  getColbUserRoleList() {
    return rest.get(get_colbUserRoleList);
  },
  /* 月リスト 取得 */
  getMonthList() {
    return rest.get(get_monthList);
  },
  /* アクセス種別リスト 取得 */
  getAccessTypeList() {
    return rest.get(get_accessTypeList);
  },
  /* サブシステムリスト 取得 */
  getSubsystemList() {
    return rest.get(get_subsystemList);
  },
  /* コンテンツメニュー 取得 */
  getContentsMenu() {
    return rest.get(get_contentsMenu);
  },
};
