const rest = require("tds-colb-cmns/src/utils/api/restCmn");
const cmnMsg = require("@/utils/message/commonMessage");
import { uriList } from "@/utils/api/restUri";

export const userInfo = {
  namespaced: true,
  //state:コンポーネントでいうdata
  state: {
    authUser: null,
  },
  //getters:コンポーネントでいうcomputed的なもの
  getters: {
    getAuthUserResource(state) {
      return state.authUser;
    },
    getUserId(state) {
      if (state.authUser === undefined || state.authUser === null) {
        return null;
      }
      return state.authUser.userId;
    },
    getUserName(state) {
      if (state.authUser === undefined || state.authUser === null) {
        return null;
      }
      return state.authUser.userName;
    },
    getBukaCd(state) {
      if (state.authUser === undefined || state.authUser === null) {
        return null;
      }
      return state.authUser.bukaCd;
    },
    getBukaName(state) {
      if (state.authUser === undefined || state.authUser === null) {
        return null;
      }
      return state.authUser.bukaName;
    },
    getEmployeeNo(state) {
      if (state.authUser === undefined || state.authUser === null) {
        return null;
      }
      return state.authUser.employeeNo;
    },
    getAuthorityMap(state) {
      if (state.authUser === undefined || state.authUser === null) {
        return null;
      }
      return state.authUser.authorityMap;
    },
  },
  //mutations:コンポーネントでいうmethod（と言うかsetter）
  //stateを唯一変更できるもの
  mutations: {
    setAuthUserResource(state, inputValue) {
      state.authUser = inputValue;
    },
  },
  //actionのコミットを使うことでミューテーションを呼び出す（コンポーネントには無い概念）
  actions: {
    replaceAuthUserResource(context, inputValue) {
      context.commit("setAuthUserResource", inputValue);
    },
    async pickAuthUserResource(context) {
      await (async () => {
        try {
          let uri = uriList.ptl_authuser_detail;

          const response = await rest.get(uri);
          if (response.resultStatus) {
            context.commit("setAuthUserResource", response.resultData);
          } else {
            throw new Error(
              cmnMsg.getMessage("PTL_E_0001", {
                0: "ログインユーザ情報",
                1: "getAuthUserResource",
                2: "情報取得処理",
              }) +
                "response = " +
                JSON.stringify(response, null, 2)
            );
          }
        } catch (err) {
          console.log(err);
          throw err;
        }
      })(context);
    },
  },
};
