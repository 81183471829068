import Vue from "vue";
import Vuex from "vuex";
import { masterData } from "./modules/masterData";
import { userInfo } from "./modules/userInfo";
import { funcNavi } from "./modules/funcNavi";
import { colb } from "./modules/colb/colb"; // 協業型ポータル
import { notification } from "tds-colb-cmns/src/store/modules/notification"; // ヘッダーの通知用

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    devMode: process.env.NODE_ENV === "development" ? true : false,
    isLoading: false,
  },
  mutations: {
    SET_DEV_MODE(state, value) {
      state.devMode = value;
    },
    SET_IS_LOADING(state, value) {
      state.isLoading = value;
    },
  },
  actions: {
    setDevMode(context, value) {
      context.commit("SET_DEV_MODE", value);
    },
    setLoading(context, value) {
      context.commit("SET_IS_LOADING", value);
    },
  },
  modules: {
    masterData,
    userInfo,
    funcNavi,
    colb,
    notification,
  },
});
