export const funcNavi = {
  namespaced: true,
  state: {
    mini: false,
    items: [],
  },
  getters: {},
  mutations: {
    SET_MINI(state, value) {
      state.mini = value;
    },
    SET_ITEMS(state, value) {
      state.items = value;
    },
  },
  actions: {
    setFuncNaviMini(context, value) {
      context.commit("SET_MINI", value);
    },
    setFuncNaviMenu(context, value) {
      context.commit("SET_ITEMS", value);
    },
    setBikkuriMark(context, value) {
      Array.from(context.state.items).forEach((nav) => {
        if (nav.key === value.key) {
          nav.bikkuriMark = value.value;
        }
      });
    },
    setNaviDisabled(context, value) {
      Array.from(context.state.items).forEach((nav) => {
        if (nav.key === value.key) {
          nav.disabled = value.value;
        }
      });
    },
    setNaviInvisible(context, value) {
      Array.from(context.state.items).forEach((nav) => {
        if (nav.key === value.key) {
          nav.invisible = value.value;
        }
      });
    },
  },
};
