import Vue from "vue";
import Vuetify from "vuetify/lib";
import ja from "./vuetify_ja";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: { ja },
    current: "ja",
  },
  theme: {
    themes: {
      light: {
        primary: "#0064D2",
      },
    },
  },
});
