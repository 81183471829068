import { AUTH_BASE_URL } from "@/utils/api/urlList";
const rest = require("tds-colb-cmns/src/utils/api/restCmn");
import { setJwt } from "tds-colb-cmns/src/utils/auth/userAuth";

//TODO:仮でURIを設定
const uriList = {
  auth_login: `${AUTH_BASE_URL}/login`,
  auth_otplogin: `${AUTH_BASE_URL}/otplogin`,
  auth_decode: `${AUTH_BASE_URL}/decode`,
  auth_logout: `${AUTH_BASE_URL}/logout`,
  auth_info: `${AUTH_BASE_URL}/info`,
};

export default {
  /* LDAP認証 */
  login(_userid, _password) {
    const uri = uriList.auth_login;
    let param = new FormData();
    param.append("username", `${_userid}`);
    param.append("password", `${_password}`);

    sessionStorage.removeItem("jwt");
    sessionStorage.removeItem("userinfo");
    return rest.post(uri, param).then((res) => {
      //JWT
      let token = res.headers["x-auth-token"];
      if (token) {
        setJwt(token);
      }

      return res;
    });
  },
  /* OTP認証 */
  otpLogin(_userid, _password) {
    const uri = uriList.auth_otplogin;
    let param = new FormData();
    param.append("userid", `${_userid}`);
    param.append("onetimepassword ", `${_password}`);

    sessionStorage.removeItem("jwt");
    sessionStorage.removeItem("userinfo");
    return rest.post(uri, param).then((res) => {
      //JWT
      let token = res.headers["x-auth-token"];
      setJwt(token);

      //ユーザ情報
      let userinfo = res.resultData;
      sessionStorage.setItem("userinfo", JSON.stringify(userinfo));

      return res;
    });
  },
  /* JWTユーザ情報 */
  decode(headers) {
    const uri = uriList.auth_decode;
    return rest.post(uri, null, headers);
  },
  /* Logout */
  logout() {
    const uri = uriList.auth_logout;

    sessionStorage.removeItem("jwt");
    sessionStorage.removeItem("userinfo");
    return rest.get(uri);
  },
  /* ユーザ情報 */
  getUser(_userid) {
    const uri = uriList.auth_info;
    let param = new FormData();
    param.append("userid", `${_userid}`);

    sessionStorage.removeItem("userinfo");
    return rest.get(uri, param).then((res) => {
      //ユーザ情報
      let userinfo = res.resultData;
      sessionStorage.setItem("userinfo", JSON.stringify(userinfo));

      return res;
    });
  },
};
