<!-- 協業型業務ポータル利用規約同意確認 -->
<template>
  <div class="view-compo-div">
    <!-- ヘッダ -->
    <header-bar isLoginMode ref="ref_header" />

    <!-- ボディ -->
    <v-main class="content text-center">
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col>&nbsp;</v-col>
        </v-row>
        <v-row>
          <v-col justify="center">
            <label for="title">(TDS)協業型業務ポータル 利用規約</label>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col>
            <text-area
              formName=""
              v-model="form.useTermsContent"
              max="5000"
              rows="10"
              overflow-y-auto
              style="width: 90%; border-width: 1px; border-style: solid"
              readonly
            />
          </v-col>
        </v-row>
        <v-row no-gutters justify="center">
          <v-col>
            <checkbox
              label="利用規約に同意する"
              v-model="form.agreement"
              resultType="init"
            />
          </v-col>
        </v-row>
        <v-row class="mb-2" no-gutters justify="center">
          <v-col>
            <v-btn color="info" :disabled="disabled" @click="next"
              >次に進む</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
      <action-confirm ref="actionConfirm" />
    </v-main>

    <!-- フッタ -->
    <footer-bar ref="ref_footer" />

    <!-- 全画面ローディングエリア -->
    <loading :active.sync="isLoading" :can-cancel="false" is-full-page />
  </div>
</template>

<script>
import HeaderBar from "tds-colb-cmns/src/components/common/HeaderBar";
import FooterBar from "tds-colb-cmns/src/components/common/FooterBar";
import Loading from "vue-loading-overlay";
import TextArea from "tds-colb-cmns/src/components/common/form/TextArea";
import Checkbox from "tds-colb-cmns/src/components/common/form/Checkbox";

import colbMixin from "tds-colb-cmns/src/mixins/colbMixin";
import ActionConfirm from "@/components/common/dialog/ActionConfirm";

import service from "@/services/common/termsOfUseService";

export default {
  name: "common-termofuse",
  mixins: [colbMixin],
  data: () => ({
    isLoading: false,
    form: {
      colbUserId: null,
      useTermsConsentFlg: null,
      colbUseTermsInfoId: null,
      useTermsNam: null,
      useTermsContent: null,
      agreement: null,
    },
  }),
  mounted() {
    this.init();
    this.$nextTick(() => {
      this.$refs.form.resetValidation();
    });
  },
  methods: {
    init() {
      this.form.colbUserId = this.$route.params.userid;
      service
        .getTermsOfUseAgreement(this.form.colbUserId)
        .then((res) => {
          this.form.colbUserId = res.resultData.colbUserId;
          this.form.colbUseTermsInfoId = res.resultData.colbUseTermsInfoId;
          this.form.useTermsNam = res.resultData.useTermsNam;
          this.form.useTermsContent = res.resultData.useTermsContent;
          this.form.agreement =
            res.resultData.useTermsConsentFlg === "1" ? true : false;
        })
        .catch((err) => {
          this.commonFail(err);
        });
    },
    next() {
      this.isLoading = true;
      this.form.useTermsConsentFlg = this.form.agreement ? "1" : "0";
      service
        .updateTermsOfUseAgreement(this.form)
        .then((res) => {
          console.debug(res.httpStatus);
          const prevUri = sessionStorage.getItem("prevUrl");
          if (prevUri) {
            sessionStorage.removeItem("prevUrl");
            location.href = prevUri;
          } else {
            this.$router.push({
              name: "colbFrameTop",
            });
          }
        })
        .catch((err) => {
          this.commonFail(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    disabled() {
      const disabled = !this.form.agreement;
      return disabled;
    },
  },
  components: {
    HeaderBar,
    FooterBar,
    Loading,
    TextArea,
    Checkbox,
    ActionConfirm,
  },
};
</script>
<style scoped>
.col {
  max-width: 90%;
}
</style>
